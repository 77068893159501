import React, { useState, useEffect } from "react";
import { connect } from "react-redux";

import {
  SectionPlanesPlugin,
  math,
} from "https://cdn.jsdelivr.net/npm/@xeokit/xeokit-sdk/dist/xeokit-sdk.es.min.js";

import SectionPlaneItem from "./SectionPlaneItem";

import "./SectionCut.css";

import {
  saveSectionPlanePluginAction,
  seSelectEntityStatusAction,
} from "../../action/xeokitAction";

// class CustomSectionPlanesPlugin extends SectionPlanesPlugin {
//   constructor(options) {
//     super(options);
//     this.overviewCanvas = document.getElementById(
//       "mySectionPlanesOverviewCanvas"
//     );
//     console.log("this", this.overviewCanvas);
//     this.overviewContext = this.overviewCanvas.getContext("2d");

//     // Call to update overview initially
//     this.updateOverview();
//   }

//   updateOverview() {
//     // Call the base update method to render the section planes
//     // super.updateOverview();

//     // Now draw custom elements on the overview canvas
//     this.drawCustomOverview();
//   }

//   drawCustomOverview() {
//     // Clear the overview canvas
//     this.overviewContext.clearRect(
//       0,
//       0,
//       this.overviewCanvas.width,
//       this.overviewCanvas.height
//     );

//     // Example: Drawing a rectangle and a number
//     const rectangles = [
//       { x: 10, y: 10, width: 40, height: 40, number: "1" },
//       { x: 60, y: 10, width: 40, height: 40, number: "2" },
//       // Add more rectangles and numbers as needed
//     ];

//     rectangles.forEach((rect) => {
//       this.overviewContext.fillStyle = "rgba(0, 150, 255, 0.5)"; // Rectangle color
//       this.overviewContext.fillRect(rect.x, rect.y, rect.width, rect.height);

//       this.overviewContext.fillStyle = "white"; // Text color
//       this.overviewContext.font = "20px Arial";
//       this.overviewContext.fillText(rect.number, rect.x + 10, rect.y + 25); // Adjust the position for text
//     });
//   }
// }

const SectionCut = (props) => {
  const [collapse, setCollapse] = useState(false);
  const sectionPlaneList = [
    "SectionPlane1",
    "SectionPlane2",
    "SectionPlane3",
    "SectionPlane4",
    "SectionPlane5",
    "SectionPlane6",
  ];
  const [sectionPlanesArray, setSectionPlanesArray] = useState([
    true,
    false,
    false,
    false,
    false,
    false,
  ]);
  const [sectionPlaneLockArray, setSectionPlaneLockArray] = useState([
    false,
    false,
    false,
    false,
    false,
    false,
  ]);
  const [selectSectionPlane, setSelectSectionPlane] = useState({
    id: 0,
    isOpen: true,
    prevId: null,
  });
  const [localSectionPlanePlugin, setLocalSectionPlanePlugin] = useState(null);
  const [mouseInput, setMouseInput] = useState(null);

  const handleClick = () => {
    collapse ? setCollapse(false) : setCollapse(true);
  };

  useEffect(() => {
    if (props.sectionCutBtn === true) {
      props.seSelectEntityStatusAction(false);
      handleClick();
      if (!props.sectionPlanePlugin) {
        console.log("whyHere 1111");
        const temp = new SectionPlanesPlugin(props.viewer, {
          overviewCanvasId: "mySectionPlanesOverviewCanvas",
          overviewVisible: true,
        });

        setLocalSectionPlanePlugin(temp);
        props.saveSectionPlanePluginAction(temp);
        setSelectSectionPlane({
          id: 0,
          isOpen: true,
          prevId: null,
        });
      } else {
        setLocalSectionPlanePlugin(props.sectionPlanePlugin);
        props.sectionPlanePlugin.showControl(selectSectionPlane.id + 1);
      }
    } else if (props.sectionCutBtn === false) {
      props.seSelectEntityStatusAction(true);
      handleClick();
      deacttivateSectionPlane();
    }
  }, [props.sectionCutBtn]);

  useEffect(() => {
    if (localSectionPlanePlugin) localSectionPlanePlugin.clear();
    console.log("ResetViewer", props.viewer);
    setSelectSectionPlane({
      id: 0,
      isOpen: true,
      prevId: null,
    });
    setSectionPlanesArray([true, false, false, false, false, false]);
    setSectionPlaneLockArray([false, false, false, false, false, false]);
  }, [props.resetSectionPlaneBtn]);

  useEffect(() => {
    console.log("???");
    if (localSectionPlanePlugin === null) return;
    let sectionPlane = null;
    if (selectSectionPlane.isOpen) {
      console.log("local", localSectionPlanePlugin);
      if (selectSectionPlane.prevId !== null) {
        console.log("isLocaked", selectSectionPlane.isLocked);

        if (sectionPlaneLockArray[selectSectionPlane.prevId])
          deacttivateSectionPlane();
        else onResetSectionPlane(selectSectionPlane.prevId);
      }
      Object.values(localSectionPlanePlugin.sectionPlanes).map((element, i) => {
        if (element.id === selectSectionPlane.id + 1) {
          sectionPlane = element;
          localSectionPlanePlugin.showControl(element.id);
        }
      });
      const mouseInput = props.viewer.scene.input.on("dblclick", (coords) => {
        var pickResult = props.viewer.scene.pick({
          canvasPos: coords,
          pickSurface: true, // <<------ This causes picking to find the intersection point on the entity
        });

        if (pickResult && pickResult.worldNormal) {
          // Disallow SectionPlanes on point clouds, because points don't have normals

          if (pickResult.entity) {
            if (!pickResult.entity.isObject) {
              return;
            }
          }
          if (sectionPlane === null) {
            sectionPlane = localSectionPlanePlugin.createSectionPlane({
              id: selectSectionPlane.id + 1,
              pos: pickResult.worldPos,
              dir: math.mulVec3Scalar(pickResult.worldNormal, -1),
            });
          } else {
            // if (!sectionPlaneLockArray[selectSectionPlane.id]) {
            sectionPlane.pos = pickResult.worldPos;
            sectionPlane.dir = math.mulVec3Scalar(pickResult.worldNormal, -1);
            // }
          }
          localSectionPlanePlugin.showControl(sectionPlane.id);
        }
      });

      setMouseInput(mouseInput);
    } else {
      deacttivateSectionPlane();
    }
  }, [selectSectionPlane]);

  const deacttivateSectionPlane = () => {
    props.viewer.scene.input.off(mouseInput);
    localSectionPlanePlugin.hideControl(selectSectionPlane.id);
  };

  const onChangeSectionPlanesArray = (id, isChecked) => {
    // Update sectionPlanesArray based on the id and isChecked

    let prevId;
    const newPlanesArray = sectionPlanesArray.map((element, i) => {
      if (element) prevId = i;
      return i === id ? isChecked : false;
    });
    console.log("new", newPlanesArray);
    setSelectSectionPlane({
      id: id,
      isOpen: isChecked,
      prevId: prevId,
    });
    setSectionPlanesArray(newPlanesArray); // Trigger re-render with updated state
  };

  const onResetSectionPlane = (id) => {
    if (localSectionPlanePlugin) {
      localSectionPlanePlugin.destroySectionPlane(id + 1);

      if (selectSectionPlane.id === id) {
        props.viewer.scene.input.off(mouseInput);
        setSelectSectionPlane({
          id: id,
          isOpen: true,
          prevId: null,
        });
      }

      const newArray = sectionPlaneLockArray.map((element, i) => {
        return id === i ? false : element;
      });
      console.log("newArray", newArray);
      setSectionPlaneLockArray(newArray);

      // const newPlanesArray = sectionPlanesArray.map((element, i) => {
      //   return i === id ? true : false;
      // });

      // setSectionPlanesArray(newPlanesArray);
    }
  };

  const onSetLockSectionPlane = (id, isLocked) => {
    const newArray = sectionPlaneLockArray.map((element, i) => {
      return id === i ? isLocked : element;
    });

    console.log("newArray", newArray);

    setSectionPlaneLockArray(newArray);
  };

  return (
    <div
      className={
        collapse ? "sectionPlane-dropdown-show" : "sectionPlane-dropdown-hidden"
      }
    >
      {sectionPlaneList.map((element, i) => (
        <SectionPlaneItem
          listTitle={element}
          switchStatus={sectionPlanesArray[i]}
          id={i}
          key={i}
          onChange={onChangeSectionPlanesArray}
          onReset={onResetSectionPlane}
          onSetLock={onSetLockSectionPlane}
          lockStatus={sectionPlaneLockArray[i]}
        />
      ))}
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    viewer: state.xeokitReducer.viewer,
    sectionCutBtn: state.xeokitReducer.sectionCutBtn,
    sectionPlanePlugin: state.xeokitReducer.sectionPlanePlugin,
    resetSectionPlaneBtn: state.xeokitReducer.resetSectionPlaneBtn,
  };
};

const mapDispatchToProps = {
  saveSectionPlanePluginAction,
  seSelectEntityStatusAction,
};

export default connect(mapStateToProps, mapDispatchToProps)(SectionCut);
