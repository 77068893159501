import React, { useState, useEffect } from "react";
import { toast } from "react-toastify";
import { connect } from "react-redux";

import Tab from "../../components/Tab/Tab";

import "./AppearenceTemplateTab.css";

import {
  setAppearenceTemplateTabStatusAction,
  findModel,
  searchAppearenceTemplateListAction,
} from "../../action/xeokitAction";

import { apiHeader, BaseURL, recordsLimit } from "../../config/apiUrl";
import { Get, Patch } from "../../axios/axios";
import { Constants } from "../../constant/constants";

const AppearenceTemplateTab = (props) => {
  const [showList, setShowList] = useState([]);

  const [isLoading, setIsLoading] = useState(false);
  const [page, setPage] = useState(1);
  const [search, setSearch] = useState("");
  const [totalPages, setTotalPages] = useState();

  const getAllListing = async (pg = page) => {
    const url = BaseURL(
      `appearances/projects/${props.projectId}?page=${pg}&limit=${recordsLimit}&search=${search}`
    );
    setIsLoading(true);
    const response = await Get(url, props.token);
    if (response !== undefined) {
      console.log("response", response);
      setShowList(response?.data);
      setTotalPages(response?.data?.totalResults);
    }
    setIsLoading(false);
  };

  useEffect(() => {
    getAllListing();
  }, []);

  useEffect(() => {
    getAllListing();
  }, [props.isAppearenceTemplateTabOpen]);

  const closeTab = () => {
    props.setAppearenceTemplateTabStatusAction(false);
  };

  const searchAppearenceTemplate = (e, _) => {
    props.searchAppearenceTemplateListAction(_);
  };

  return (
    <div className="appearence-profile-tab">
      <Tab
        isOpen={props.isAppearenceTemplateTabOpen}
        onClose={closeTab}
        tabName={"Appearence Template Tab"}
      >
        <div className="appearence-profile-tab-toolbar">
          <div></div>
        </div>

        <ul className="appearence-profile-tab-ul">
          {showList.map((_, i) => (
            <li
              onClick={(e) => searchAppearenceTemplate(e, _)}
              className="appearence-profile-tab-li"
              key={i}
              value={_.name}
            >
              <div className="appearence-profile-tab-li-searchset">
                {_.name}
              </div>
            </li>
          ))}
        </ul>
      </Tab>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    appearenceTemplateList:
      state.AppearenceTemplateReducer.appearenceTemplateList,
    isAppearenceTemplateTabOpen:
      state.AppearenceTemplateReducer.isAppearenceTemplateTabOpen,
    projectName: state.ProjectReducer.projectName,
    projectId: state.ProjectReducer.projectId,
    token: state.AuthReducer.token,
  };
};

const mapDispatchToProps = {
  setAppearenceTemplateTabStatusAction,
  findModel,
  searchAppearenceTemplateListAction,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(AppearenceTemplateTab);
