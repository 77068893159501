import { Constants } from "../constant/constants";

const initialState = {
  isLogin: false,
  user: null,
  token: "",
};
export function AuthReducer(state = initialState, action) {
  switch (action.type) {
    case Constants.USER_LOGIN: {
      return {
        ...state,
        isLogin: true,
        user: action.payload?.user,
        token: action.payload?.token,
      };
    }
    case Constants.USER_LOGOUT: {
      return { ...state, isLogin: false, user: null, token: "" };
    }
    default:
      return state;
  }
}
