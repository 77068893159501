import logo from "./logo.svg";
import "./App.css";
import "react-toastify/dist/ReactToastify.min.css";
import { ToastContainer } from "react-toastify";
import { Route, BrowserRouter, Routes } from "react-router-dom";
import MainPage from "./pages/mainPage";
import Login from "./pages/Login/login";
import Project from "./pages/Project/project";

import BeforeLoginRoute from "./utlity/BeforeLoginRoute";
import ProtectedRoute from "./utlity/ProtectedRoute";

function App() {
  return (
    <div className="App" style={{ width: "100%", height: "100%" }}>
      <ToastContainer />
      <BrowserRouter>
        <Routes>
          <Route
            path="/login"
            exact
            element={<BeforeLoginRoute element={<Login />} />}
          />
          <Route
            path="/projects"
            exact
            element={<ProtectedRoute element={<Project />} />}
          />
          <Route
            path="/"
            exact
            element={<ProtectedRoute element={<MainPage />} />}
          />
        </Routes>
      </BrowserRouter>
      {/* <Login /> */}
      {/* <MainPage /> */}
    </div>
  );
}

export default App;
