import React, { useState } from "react";
import "./LogTab.css";

const LogTab = ({ logTabData, issueId, saveUpdatedIssue }) => {
  const [commandData, setCommandData] = useState(null);

  const handleCommandChange = (e) => setCommandData(e.target.value);

  const handleCommandSubmit = () =>
    saveUpdatedIssue(issueId, "comment", commandData);

  return (
    <div className="log-tab">
      <div className="log-tab-logs-container">
        {logTabData.map((element, i) => (
          <div key={i}>
            {Object.entries(element).map(([objectKey, objectValue]) => {
              return objectKey === "markUp" ? (
                <div className="log-tab-row" key={objectKey}>
                  <div className="log-tab-row-title">MarkUp</div>
                  <div>
                    <img
                      src={objectValue}
                      alt="Mark up"
                      width="100%"
                      height={250}
                      className="log-tab-markup"
                    />
                  </div>
                </div>
              ) : (
                <div className="log-tab-row" key={objectKey}>
                  {objectKey === "createdDate" ? (
                    <div className="log-tab-row-created">
                      updated at {objectValue}
                    </div>
                  ) : (
                    <>
                      <div className="log-tab-row-title">
                        {objectKey.toUpperCase()} was changed
                      </div>
                      <div className="log-tab-row-content">{objectValue}</div>
                    </>
                  )}
                </div>
              );
            })}
          </div>
        ))}
      </div>
      <div className="log-tab-comment-container">
        <div className="detail-tab-row-title">
          <div>Comments</div>
        </div>
        <div className="detail-tab-row-comment-content">
          <textarea
            className="detail-tab-row-comment-text"
            type="text"
            onChange={handleCommandChange}
          ></textarea>
          <div>
            <button className="custom-button" onClick={handleCommandSubmit}>
              Leave Comment
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default LogTab;
