import { Constants } from "../constant/constants";

const initialState = {
  context: null,
  name: null,
  type: null,
  uuid: null,
  id: null,
  modelIds: [],
  modelTypes: [],
  modelNames: [],
  propertyFlag: false,
  multiSelectList: [],
  properties: {},
  selectedData: null,
  commonProperty: null,
};
export function PropertyReducer(state = initialState, action) {
  switch (action.type) {
    case Constants.GET_MODEL_PROPERTIES:
      console.log("PROPERTY", action.payload);
      return {
        ...state,
        properties: action.payload,
      };
    case Constants.SET_PROPERTY_TAB_STATUS: {
      let flag;
      if (state.propertyFlag) {
        flag = false;
      } else flag = true;
      return { ...state, propertyFlag: flag };
    }
    case Constants.SET_MULTI_SELECT_ENTITY: {
      console.log("reducer", action.payload);
      return { ...state, multiSelectList: action.payload };
    }
    case Constants.SET_SELECT_PROPERTY_DATA: {
      return { ...state, selectedData: action.payload };
    }
    case Constants.SET_COMMON_PROPERTY_DATA: {
      console.log("commonProperty", action.payload);
      return { ...state, commonProperty: action.payload };
    }
    default:
      return state;
  }
}
