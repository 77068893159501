import React, { useState, useEffect } from "react";
import { toast } from "react-toastify";
import { connect } from "react-redux";

import Dropdown from "../../components/DropDown/Dropdown";
import Modal from "../../components/Modal/Modal";
import CustomColorPicker from "../../components/ColorPicker/ColorPicker";
import Tab from "../../components/Tab/Tab";

import "./AppearenceProfile.css";

import {
  setAppearenceProfileTabStatusAction,
  saveSearchList,
  findModel,
  saveAppearenceTemplateListAction,
  saveAppearenceProfileListAction,
} from "../../action/xeokitAction";

import { apiHeader, BaseURL, recordsLimit } from "../../config/apiUrl";
import { Get, Post } from "../../axios/axios";
import { Constants } from "../../constant/constants";

import PNG_BINOCULAR from "../../asset/binocular.png";

const AppearenceProfileTab = (props) => {
  const [openColorModal, setOpenColorModal] = useState(false);
  const [openTransparencyModal, setOpenTransparencyModal] = useState(false);
  const [openHideModal, setOpenHideModal] = useState(false);
  const [openSearchSetsModal, setOpenSearchSetsModal] = useState(false);
  const [openSaveModal, setOpenSaveModal] = useState(false);

  const handleClose = () => {
    setOpenColorModal(false);
    setOpenTransparencyModal(false);
    setOpenSearchSetsModal(false);
    setOpenSaveModal(false);
    setOpenHideModal(false);
  };

  const [showList, setShowList] = useState([]);
  const [searchSetsList, setSearchSetsList] = useState([]);

  const [selectedShowList, setSelectedShowList] = useState(null);
  const [selectedSearchSets, setSelectedSearchSets] = useState([]);
  const settingArray = ["Transparency", "Hide/Unhide", "Color"];
  const [settingValue, setSettingValue] = useState("Transparency");

  const [selectedTransparency, setSelectedTransparency] = useState();

  const [appearenceTemplate, setAppearenceTemplate] = useState();

  const [transparencyValue, setTransparencyValue] = useState(0);

  const [isLoading, setIsLoading] = useState(false);
  const [page, setPage] = useState(1);
  const [search, setSearch] = useState("");
  const [totalPages, setTotalPages] = useState();

  const getAllListing = async (pg = page) => {
    const url = BaseURL(
      `searches/projects/${props.projectId}?page=${pg}&limit=${recordsLimit}&search=${search}`
    );
    setIsLoading(true);
    const response = await Get(url, props.token);
    if (response !== undefined) {
      console.log("response", response);
      setSearchSetsList(
        response?.data.map((ele, i) => ({
          name: ele.name,
          property: ele.property,
          value: ele.value,
          color: ele.color,
        }))
      );
      setTotalPages(response?.data?.totalResults);
    }
    setIsLoading(false);
  };

  useEffect(() => {
    getAllListing();
  }, [props.setAppearenceProfileTabStatus]);

  useEffect(() => {
    // if (props.selectedAppearenceTemplateList === null) {
    //   setShowList(props.appearenceProfileList);
    // } else {
    //   props.appearenceTemplateList.map((element, i) => {
    //     if (
    //       element.appearenceTemplateName ===
    //       props.selectedAppearenceTemplateList
    //     ) {
    //       setShowList(element.appearenceTemplateValue);
    //     }
    //   });
    // }

    // setSearchSetsList(props.searchSaveList);
    // console.log("show", props.searchSaveList);

    const {
      selectedAppearenceTemplateList,
      appearenceProfileList,
      appearenceTemplateList,
      searchSaveList,
    } = props;

    //appearenceProfileList is always null. It should be allocated the value to show
    //so change this part
    if (selectedAppearenceTemplateList === null) {
      // console.log("I think you are here")
      // setShowList(appearenceProfileList);
    } else {
      const selectedTemplate = appearenceTemplateList.find(
        (template) =>
          template.appearenceTemplateName === selectedAppearenceTemplateList
      );
      if (selectedTemplate) {
        setShowList(selectedTemplate.appearenceTemplateValue);
      }
    }

    // setSearchSetsList(searchSaveList);
  }, [props]);

  const getSearchSet = (e) => {
    setSelectedSearchSets(e.target.textContent);
  };

  let dropDownFlag = false;
  const handleDropDownClick = (e, name) => {
    if (dropDownFlag) {
      setSelectedShowList(name);
      const currentValue = e.target.value;
      if (currentValue === "Color") setOpenColorModal(true);
      else if (currentValue === "Transparency") setOpenTransparencyModal(true);
      else if (currentValue === "Hide/Unhide") handleHide(name);

      dropDownFlag = false;
    } else dropDownFlag = true;
  };

  const [selectedColor, setSelectedColor] = useState("#FFFFFF");
  const [selectedRgbColor, setSelectedRgbColor] = useState([]);

  const handleColorChange = (color) => {
    setSelectedColor(color.hex);
    const rgbValue = hexToRgb(color.hex);
    setSelectedRgbColor(rgbValue);
  };

  const saveColor = () => {
    setOpenColorModal(false);

    console.log("color", selectedRgbColor);
    // Update the color in the list
    const updatedList = showList.map((item) =>
      item.name === selectedShowList
        ? {
            ...item,
            color: selectedRgbColor,
            transparency: null,
            hide: null,
          }
        : item
    );

    console.log("updatedlist", updatedList);
    setShowList(updatedList);

    // Save the updated list
    props.saveSearchList(updatedList);
  };
  const searchEntity = (e) => {};
  const hexToRgb = (hex) => {
    // Remove the hash at the start if it's there
    hex = hex.replace(/^#/, "");

    // Parse r, g, b values
    let r = parseInt(hex.slice(0, 2), 16) / 255;
    let g = parseInt(hex.slice(2, 4), 16) / 255;
    let b = parseInt(hex.slice(4, 6), 16) / 255;

    return [r, g, b];
  };

  const onChangeTransparencyValue = (e) => {
    setSelectedTransparency(e.target.value / 100);
  };

  const saveTransparency = () => {
    // Close the transparency modal
    setOpenTransparencyModal(false);

    // Update the transparency in the list
    const updatedList = showList.map((item) =>
      item.name === selectedShowList
        ? {
            ...item,
            transparency: selectedTransparency,
            color: null,
            hide: null,
          }
        : item
    );

    setShowList(updatedList);
    // Save the updated list
    props.saveSearchList(updatedList);

    // Update the transparency value
    setTransparencyValue(selectedTransparency * 100);
  };

  const closeTab = () => {
    props.setAppearenceProfileTabStatusAction(false);
  };

  const selectSearchSets = () => {
    setOpenSearchSetsModal(false);
    // Find the selected search set
    const selectedSet = searchSetsList.find(
      (item) => item.name === selectedSearchSets
    );
    selectedSet.hide = null;
    // If a matching search set is found, add it to the show list
    if (selectedSet) {
      setShowList((prevList) => [...prevList, selectedSet]);
    }
  };
  const addRule = () => {
    setOpenSearchSetsModal(true);
  };

  const onChangeAppearenceTemplate = (e) => {
    setAppearenceTemplate(e.target.value);
  };

  const saveAppearenceTemplate = async () => {
    setOpenSaveModal(false);

    // Create a deep copy of the current appearance template list
    const updatedTemplates = [...props.appearenceTemplateList];

    const temp = showList.filter((item) => !item.hide);

    const url = BaseURL(`appearances`);
    const params = {
      name: appearenceTemplate,
      template: temp,
      projects: [`${props.projectName}`],
    };

    setIsLoading(true);

    const response = await Post(url, params, apiHeader(props.token));
    if (response !== undefined) {
      console.log("response", response);
      toast.success("Appearance added successfully");
    }

    setIsLoading(false);

    // Add the new appearance template to the list
    updatedTemplates.push({
      appearenceTemplateName: appearenceTemplate,
      appearenceTemplateValue: [...temp],
    });

    console.log("showlist", showList);
    props.saveAppearenceTemplateListAction(updatedTemplates);
  };

  const updateExisting = () => {
    const updatedTemplates = props.appearenceTemplateList.map((template) =>
      template.appearenceTemplateName === props.selectedAppearenceTemplateList
        ? { ...template, appearenceTemplateValue: showList }
        : template
    );
  };

  const handleApply = () => {
    // const temp = showList.map((item) => {
    //   if (!item.hide) {
    //     return item;
    //   }
    // });
    const temp = showList.filter((item) => !item.hide);
    props.saveAppearenceProfileListAction([...temp]);
  };

  const handleHide = (value) => {
    // Update the transparency in the list
    console.log("showList", showList);
    console.log("selectedList", selectedShowList);
    const updatedList = showList.map((item) =>
      item.searchSetName === value
        ? {
            ...item,
            hide: item.hide ? false : true,
            transparency: null,
            color: null,
          }
        : item
    );

    // showList.map((item) => {
    //   if (item.searchSetName === selectedShowList) {
    //     console.log("hide", item.hide);
    //     const test = item.hide ? false : true;

    //     console.log("test", test);
    //   }
    // });

    console.log("updatedList", updatedList);

    setShowList(updatedList);
    handleClose();
  };

  const handleLabel = (searchSetName) => {
    showList.map((item) => {
      if (item.searchSetName === searchSetName) {
        if (item.modelColor) setOpenColorModal(true);
        else if (item.modelTransparency) setOpenTransparencyModal(true);
        else if (item.hide !== null) handleHide(searchSetName);
      }
    });
  };

  return (
    <div className="appearence-profile-tab">
      <Tab
        isOpen={props.setAppearenceProfileTabStatus}
        onClose={closeTab}
        tabName={"Appearence Profile Tab"}
      >
        <div className="appearence-profile-tab-toolbar">
          <div>
            <button
              className="custom-button"
              type="button"
              onClick={() => setOpenSaveModal(true)}
            >
              Save as New
            </button>
          </div>
          <div>
            <button
              className="custom-button"
              type="button"
              onClick={() => updateExisting()}
            >
              Update Existing
            </button>
          </div>
        </div>

        <ul className="appearence-profile-tab-ul">
          {showList.map((_, i) => (
            <li
              onClick={(e) => searchEntity(e)}
              className="appearence-profile-tab-li"
              key={i}
              value={_.name}
            >
              <div className="appearence-profile-tab-li-searchset">
                {_.name}
              </div>
              <div className="appearence-profile-tab-li-setting">
                <select
                  className="select"
                  onClick={(e) => handleDropDownClick(e, _.name)}
                >
                  {settingArray?.map((element, i) => (
                    <option value={element} key={i}>
                      {element}
                    </option>
                  ))}
                </select>
              </div>
              <div onClick={() => handleLabel(_.name)}>
                {_.transparency ? <label>{_.transparency * 100}%</label> : null}
                {_.color ? (
                  <div
                    style={{
                      backgroundColor: `RGB(${_.color[0] * 255}, ${
                        _.color[1] * 255
                      }, ${_.color[2] * 255})`,
                      width: "20px",
                      height: "20px",
                      borderRadius: "15px",
                    }}
                  ></div>
                ) : null}
                {_.hide !== null ? (
                  <label>{_.hide ? "Hide" : "Unhide"}</label>
                ) : null}
              </div>
            </li>
          ))}
        </ul>
        <div>
          <button className="custom-button" onClick={addRule}>
            Add Rule
          </button>
          <button className="custom-button" onClick={handleApply}>
            Apply
          </button>
        </div>

        <Modal isOpen={openColorModal}>
          <div>
            <div className="modal-header">
              <label>Set Color</label>
              <span className="modal-close" onClick={handleClose}>
                X
              </span>
            </div>
            <div className="modal-body">
              <div style={{ backgroundColor: selectedColor }}>
                <CustomColorPicker
                  hexCode={selectedColor}
                  onChange={handleColorChange}
                />
                <button
                  className="custom-button"
                  type="button"
                  onClick={() => saveColor()}
                >
                  save
                </button>
              </div>
            </div>
          </div>
        </Modal>
        <Modal isOpen={openTransparencyModal}>
          <div>
            <div className="modal-header">
              <label>Set TransParency</label>
              <span className="modal-close" onClick={handleClose}>
                X
              </span>
            </div>
            <div className="modal-body">
              <div>
                <input
                  onChange={(e) => onChangeTransparencyValue(e)}
                  type="number"
                  min="0"
                  max="100"
                  placeholder="Please input between 0 and 100"
                ></input>
                <button
                  className="custom-button"
                  type="button"
                  onClick={() => saveTransparency()}
                >
                  save
                </button>
              </div>
            </div>
          </div>
        </Modal>
        <Modal isOpen={openSearchSetsModal}>
          <div className="modal-custom">
            <div className="modal-header">
              <label>Set TransParency</label>
              <span className="modal-close" onClick={handleClose}>
                X
              </span>
            </div>
            <div className="modal-body">
              <h5>SearchSet</h5>
              <ul className="modal-ul">
                {searchSetsList.map((_, i) => (
                  <li
                    onClick={(e) => getSearchSet(e)}
                    className={
                      selectedSearchSets === _.name
                        ? "modal-li-clicked"
                        : "modal-li"
                    }
                    key={i}
                    value={_.name}
                  >
                    <div>
                      <img src={PNG_BINOCULAR} />
                    </div>
                    <div>{_.name}</div>
                  </li>
                ))}
              </ul>
              <button className="custom-button" onClick={selectSearchSets}>
                Select
              </button>
            </div>
          </div>
        </Modal>
        <Modal isOpen={openSaveModal}>
          <div>
            <div className="modal-header">
              <label>Save Modal</label>
              <span className="modal-close" onClick={handleClose}>
                X
              </span>
            </div>
            <div className="modal-body">
              <div>
                <h5>AppearenceProfile Template Name</h5>
                <input
                  onChange={(e) => onChangeAppearenceTemplate(e)}
                  placeholder="Please AppearenceProfile Template Name"
                ></input>
                <button
                  className="custom-button"
                  type="button"
                  onClick={saveAppearenceTemplate}
                >
                  save
                </button>
              </div>
            </div>
          </div>
        </Modal>
      </Tab>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    setAppearenceProfileTabStatus:
      state.AppearenceProfileReducer.setAppearenceProfileTabStatus,
    appearenceProfileList: state.AppearenceProfileReducer.appearenceProfileList,
    appearenceTemplateList:
      state.AppearenceTemplateReducer.appearenceTemplateList,
    searchSaveList: state.SearchSetsReducer.searchSaveList,
    selectedAppearenceTemplateList:
      state.AppearenceTemplateReducer.selectedAppearenceTemplateList,
    projectName: state.ProjectReducer.projectName,
    projectId: state.ProjectReducer.projectId,
    token: state.AuthReducer.token,
  };
};

const mapDispatchToProps = {
  setAppearenceProfileTabStatusAction,
  saveSearchList,
  findModel,
  saveAppearenceTemplateListAction,
  saveAppearenceProfileListAction,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(AppearenceProfileTab);
