import { Constants } from "../constant/constants";

const initialState = {
  setClashDetectionTabStatus: false,
  clashDetectionFlag: false,
  clashDetectionList: [],
  clashDetectionListFlag: false,
  searchSet1: null,
  searchSet2: null,
  clashDetectionArray1: [],
  clashDetectionArray2: [],
  setClashTestTabStatus: false,
};
export function ClashDetectionReducer(state = initialState, action) {
  switch (action.type) {
    case Constants.SET_CLASHDETECTIONTAB_STATUS: {
      return { ...state, setClashDetectionTabStatus: action.payload };
    }
    case Constants.DETECT_COLLISION: {
      let flag;
      if (state.clashDetectionFlag) {
        flag = false;
      } else flag = true;
      console.log("detectcollision", action.payload);
      console.log("detectcollisionFlag", flag);
      return {
        ...state,
        searchSet1: action.payload.searchSet1,
        searchSet2: action.payload.searchSet2,
        clashDetectionFlag: flag,
      };
    }
    case Constants.SAVE_CLASH_DETECTION_LIST: {
      console.log("searchreducer", action.payload);
      let flag;
      if (state.clashDetectionListFlag) {
        flag = false;
      } else flag = true;
      return {
        ...state,
        clashDetectionList: action.payload,
        clashDetectionListFlag: flag,
      };
    }
    case Constants.SAVE_CLASHDETECTION_ARRAY: {
      console.log("clashreducer", action.payload.array1);
      console.log("clashreducer", action.payload.array2);
      return {
        ...state,
        clashDetectionArray1: action.payload.array1,
        clashDetectionArray2: action.payload.array2,
      };
    }
    case Constants.SET_CLASH_TEST_TAB_STATUS: {
      console.log("clashTestTab0", action.payload);
      return {
        ...state,
        setClashTestTabStatus: action.payload,
      };
    }
    default:
      return state;
  }
}
