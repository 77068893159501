import { Constants } from "../constant/constants";

const initialState = {
  setTreeViewStatus: false,
};
export function TreeViewReducer(state = initialState, action) {
  switch (action.type) {
    case Constants.SET_TREE_VIEW_STATUS: {
      let flag;
      if (state.setTreeViewStatus) {
        flag = false;
      } else flag = true;
      return { ...state, setTreeViewStatus: flag };
    }
    default:
      return state;
  }
}
