import { Constants } from "../constant/constants";

const initialState = {
  isIssueTrackOpen: false,
  IssuesArray: [],
  logsArray: [],
  updateScreenIssueId: null,
  setAnnotationOptionTabStatus: false,
};
export function IssueTrackReducer(state = initialState, action) {
  switch (action.type) {
    case Constants.SET_ISSUE_TRACK_STATUS: {
      return { ...state, isIssueTrackOpen: action.payload };
    }
    case Constants.SAVE_ISSUE_ARRAY: {
      console.log("issue", action.payload);
      return { ...state, IssuesArray: action.payload };
    }
    case Constants.SAVE_LOG_ARRAY: {
      return { ...state, logsArray: action.payload };
    }
    case Constants.SET_UPDATE_SCREEN_ISSUE_ID: {
      return { ...state, updateScreenIssueId: action.payload };
    }
    case Constants.SET_ANNOTATION_OPTION_TAB: {
      console.log("ACTION ANNOTATION");
      return { ...state, setAnnotationOptionTabStatus: action.payload };
    }
    default:
      return state;
  }
}
