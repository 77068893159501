import React, { useEffect, useState } from "react";
import { connect } from "react-redux";

import {
  DistanceMeasurementsPlugin,
  DistanceMeasurementsMouseControl,
  ContextMenu,
  AnnotationsPlugin,
} from "https://cdn.jsdelivr.net/npm/@xeokit/xeokit-sdk/dist/xeokit-sdk.es.min.js";

import Modal from "../../components/Modal/Modal";
import Tab from "../../components/Tab/Tab";

import "./Measure.css";

import {
  getMeasurementMouseControlInstantAction,
  saveDistanceMeasurementArrayAction,
  saveDistanceMeasurementPluginAction,
  seSelectEntityStatusAction,
} from "../../action/xeokitAction";

const Measure = (props) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [measurementName, setMeasurementName] = useState("");
  const [measurementNameArray, setMeasurementNameArray] = useState([]);
  const [selectedMeasurementId, setSelectedMeasurementId] = useState(null);
  const [objectMeasurementArray, setObjectMeasurementArray] = useState([]);
  const [measureBtnStatus, setMeasureBtnStatus] = useState(1);
  const [twoObjectsBtnStatus, setTwoObjectsBtnStatus] = useState(null);
  const [elevationMouseInput, setElevationMouseInput] = useState(null);
  const [elevationAnnotation, setElevationAnnotation] = useState(null);
  const [twoPointsArray, setTwoPointsArray] = useState([]);
  const [twoObjectsArray, setTwoObjectsArray] = useState([]);

  useEffect(() => {
    if (props.measureBtn) {
      console.log("???Viewer???", props.viewer);
      props.seSelectEntityStatusAction(false);
      if (!props.distanceMeasurementPlugin) {
        initializeDistanceMeasurementPlugin();
      } else {
        activateDistanceMeasurements();

        measureTwoPoints();
      }
    } else if (props.measureBtn === false) {
      props.seSelectEntityStatusAction(true);
      resetPreviousMeasurements();
      const measurements = Object.values(
        props.distanceMeasurementPlugin.measurements
      );

      const circularReplacer = () => {
        const seen = new WeakMap();
        let id = 0;

        return (key, value) => {
          if (typeof value === "object" && value !== null) {
            if (seen.has(value)) {
              return { $ref: seen.get(value) };
            }
            const uniqueId = `#${id++}`;
            seen.set(value, uniqueId);
          }
          return value;
        };
      };

      const circularReviver = () => {
        const refs = new Map();

        return (key, value) => {
          if (value && typeof value === "object") {
            if (value.$ref) {
              return refs.get(value.$ref) || {};
            }
            const uniqueId = value.$ref ? value.$ref : `#${refs.size}`;
            refs.set(uniqueId, value);
          }
          return value;
        };
      };

      // Serialize
      const JSONData = JSON.stringify(measurements, circularReplacer());

      // Deserialize (restore circular references)
      const circularObject = JSON.parse(JSONData, circularReviver());

      console.log(circularObject); // Your restored circular object

      let flag;
      measurements.map((element, i) => {
        flag = true;
        measurementNameArray.map((item, i) => {
          if (item.id === element.id) {
            flag = false;
          }
        });
        if (flag) element.destroy();
      });
      console.log("id1", props.distanceMeasurementPlugin);
      setTwoPointsArray([]);
      setTwoObjectsArray([]);
      setObjectMeasurementArray([]);
    }

    console.log("viewer", props.viewer);
  }, [props.measureBtn]);

  const initializeDistanceMeasurementPlugin = () => {
    const distanceMeasurementsPlugin = new DistanceMeasurementsPlugin(
      props.viewer
    );
    const distanceMeasurementsMouseControl =
      new DistanceMeasurementsMouseControl(distanceMeasurementsPlugin, {
        snapping: true,
      });

    props.saveDistanceMeasurementPluginAction(distanceMeasurementsPlugin);
    props.getMeasurementMouseControlInstantAction(
      distanceMeasurementsMouseControl
    );

    const distanceMeasurementsContextMenu = new ContextMenu({
      items: [
        [
          {
            title: "Save this Measurement",
            doAction: function (context) {
              setSelectedMeasurementId(context.distanceMeasurement.id);
              openModal();
            },
          },
          {
            title: "Clear",
            doAction: function (context) {
              context.distanceMeasurement.destroy();
            },
          },
          {
            title: "Origin Visible",
            doAction: function (context) {
              context.distanceMeasurement.visible =
                !context.distanceMeasurement.visible;
            },
          },
          {
            getTitle: (context) => {
              return context.distanceMeasurement.axisVisible
                ? "Hide Axis"
                : "Show Axis";
            },
            doAction: function (context) {
              context.distanceMeasurement.axisVisible =
                !context.distanceMeasurement.axisVisible;
            },
          },
          {
            getTitle: (context) => {
              return context.distanceMeasurement.xLabelEnabled &&
                context.distanceMeasurement.labelsVisible
                ? "Disable X Label"
                : "Enable X Label";
            },
            doAction: function (context) {
              context.distanceMeasurement.xLabelEnabled =
                !context.distanceMeasurement.xLabelEnabled;
            },
          },
          {
            getTitle: (context) => {
              return context.distanceMeasurement.yLabelEnabled &&
                context.distanceMeasurement.labelsVisible
                ? "Disable Y Label"
                : "Enable Y Label";
            },
            doAction: function (context) {
              context.distanceMeasurement.yLabelEnabled =
                !context.distanceMeasurement.yLabelEnabled;
            },
          },
          {
            getTitle: (context) => {
              return context.distanceMeasurement.zLabelEnabled &&
                context.distanceMeasurement.labelsVisible
                ? "Disable Z Label"
                : "Enable Z Label";
            },
            doAction: function (context) {
              context.distanceMeasurement.zLabelEnabled =
                !context.distanceMeasurement.zLabelEnabled;
            },
          },
          {
            getTitle: (context) => {
              return context.distanceMeasurement.lengthLabelEnabled &&
                context.distanceMeasurement.labelsVisible
                ? "Disable Length Label"
                : "Enable Length Label";
            },
            doAction: function (context) {
              context.distanceMeasurement.lengthLabelEnabled =
                !context.distanceMeasurement.lengthLabelEnabled;
            },
          },
          {
            getTitle: (context) => {
              return context.distanceMeasurement.labelsVisible
                ? "Hide All Labels"
                : "Show All Labels";
            },
            doAction: function (context) {
              context.distanceMeasurement.labelsVisible =
                !context.distanceMeasurement.labelsVisible;
            },
          },
        ],
        [
          {
            title: "Clear All",
            getEnabled: function (context) {
              return (
                Object.keys(context.distanceMeasurementsPlugin.measurements)
                  .length > 0
              );
            },
            doAction: function (context) {
              context.distanceMeasurementsPlugin.clear();
            },
          },
          {
            getTitle: () => {
              return "Cancel Measurement";
            },
            doAction: function () {
              distanceMeasurementsMouseControl.reset();
            },
          },
        ],
      ],
    });

    distanceMeasurementsContextMenu.on("hidden", () => {
      if (distanceMeasurementsContextMenu.context.distanceMeasurement) {
        distanceMeasurementsContextMenu.context.distanceMeasurement.setHighlighted(
          false
        );
      }
    });

    distanceMeasurementsPlugin.on("mouseOver", (e) => {
      e.distanceMeasurement.setHighlighted(true);
    });

    distanceMeasurementsPlugin.on("mouseLeave", (e) => {
      if (
        distanceMeasurementsContextMenu.shown &&
        distanceMeasurementsContextMenu.context.distanceMeasurement.id ===
          e.distanceMeasurement.id
      ) {
        return;
      }
      e.distanceMeasurement.setHighlighted(false);
    });

    distanceMeasurementsPlugin.on("contextMenu", (e) => {
      // Context menu logic here
      distanceMeasurementsContextMenu.context = {
        // Must set context before showing menu
        viewer: props.viewer,
        distanceMeasurementsPlugin: distanceMeasurementsPlugin,
        distanceMeasurement: e.distanceMeasurement,
      };
      distanceMeasurementsContextMenu.show(e.event.clientX, e.event.clientY);
      e.event.preventDefault();
    });
    distanceMeasurementsMouseControl.activate();
  };

  const activateDistanceMeasurements = () => {
    console.log("array", props.distanceMeasurementPlugin);
    const measurements = props.distanceMeasurementPlugin.measurements;
    props.distanceMeasurementArray.forEach((element) => {
      console.log(element);
      if (element.type === 1) {
        twoPointsArray.push(element.id);
      } else if (element.type === 2) {
        twoObjectsArray.push(element.id);
      }
    });
  };

  const openModal = () => setIsModalOpen(true);
  const handleClose = () => setIsModalOpen(false);

  const saveMeasurementName = () => {
    if (measurementName.trim() === "") {
      alert("Measurement name cannot be empty.");
      return;
    }
    console.log(props.viewer);
    measurementNameArray.push({
      name: measurementName,
      id: selectedMeasurementId,
      type: measureBtnStatus,
    });
    props.saveDistanceMeasurementArrayAction(measurementNameArray);
    setIsModalOpen(false);
  };

  const measureTwoPoints = () => {
    resetPreviousMeasurements();
    props.distanceMeasurementsMouse.activate();
    setMeasureBtnStatus(1);
    console.log("TwoPoints", twoPointsArray);
    if (twoPointsArray.length < 1) return;
    twoPointsArray.map((element, i) => {
      const item = props.distanceMeasurementPlugin.measurements[element];
      console.log(item);
      item.visible = true;
    });
  };

  const measureTwoObjects = () => {
    resetPreviousMeasurements();
    setMeasureBtnStatus(2);
    setTwoObjectsBtnStatus(
      props.viewer.scene.input.on("mouseclicked", async (coords) => {
        handleObjectMeasurement(coords);
      })
    );
    if (twoObjectsArray.length < 1) return;
    twoObjectsArray.map((element, i) => {
      const item = props.distanceMeasurementPlugin.measurements[element];
      item.visible = true;
    });

    if (objectMeasurementArray.length > 0) {
      const entity1 = props.viewer.scene.objects[objectMeasurementArray[0].id];
      const entity2 = props.viewer.scene.objects[objectMeasurementArray[1].id];
      entity1.selected = true;
      entity2.selected = true;
    }
  };

  const measureElevation = () => {
    resetPreviousMeasurements();
    setMeasureBtnStatus(3);
    const annotations = new AnnotationsPlugin(props.viewer, {
      // Default HTML template for marker position
      markerHTML:
        "<div class='elevation-marker' style='background-color: {{markerBGColor}};'>{{glyph}}</div>",
      // Default HTML template for label
      // labelHTML:
      //   "<div class='annotation-label' style='background-color: {{labelBGColor}};'>" +
      //   "<div class='annotation-title'>{{title}}</div><div class='annotation-desc'>{{description}}</div></div>",
      // Default values to insert into the marker and label templates
      values: {
        markerBGColor: "white",
        labelBGColor: "red",
        glyph: "X",
        //   title: "Untitled",
        //   description: "No description",
      },
    });
    annotations.on("markerClicked", (annotation) => {
      annotation.labelShown = !annotation.labelShown;
    });
    setElevationMouseInput(
      props.viewer.scene.input.on("mouseclicked", async (coords) => {
        handleElevationMeasurement(coords, annotations);
      })
    );
  };

  const resetPreviousMeasurements = () => {
    console.log(measureBtnStatus);
    if (measureBtnStatus === 0) {
    } else if (measureBtnStatus === 1) {
      props.distanceMeasurementsMouse.deactivate();
      const measurementsArray = Object.values(
        props.distanceMeasurementPlugin.measurements
      ).filter((item) => item.visible === true);
      console.log("measurementsA", measurementsArray);
      if (measurementsArray.length < 1) return;
      measurementsArray.map((element, i) => {
        twoPointsArray.push(element.id);
      });
      if (twoPointsArray.length < 1) return;
      twoPointsArray.map((element, i) => {
        const item = props.distanceMeasurementPlugin.measurements[element];
        item.visible = false;
      });
      console.log("HERE???");
    }
    if (measureBtnStatus === 2) {
      const measurementsArray = Object.values(
        props.distanceMeasurementPlugin.measurements
      ).filter((item) => item.visible === true);
      console.log("measurementsA", measurementsArray);

      props.viewer.scene.input.off(twoObjectsBtnStatus);
      if (objectMeasurementArray.length > 0) {
        console.log("objectMeasurementArray", objectMeasurementArray);
        const entity1 =
          props.viewer.scene.objects[objectMeasurementArray[0].id];
        const entity2 =
          props.viewer.scene.objects[objectMeasurementArray[1].id];
        entity1.selected = false;
        entity2.selected = false;
        // objectMeasurementArray.pop();
        // objectMeasurementArray.pop();
      }
      if (measurementsArray.length < 1) return;
      measurementsArray.map((element, i) => {
        twoObjectsArray.push(element.id);
      });
      if (twoPointsArray.length < 1) return;
      twoObjectsArray.map((element, i) => {
        const item = props.distanceMeasurementPlugin.measurements[element];
        item.visible = false;
      });
    } else if (measureBtnStatus === 3) {
      props.viewer.scene.input.off(elevationMouseInput);
      elevationAnnotation.destroy();
    }

    // const measurementsArray = Object.entries(
    //   props.distanceMeasurementPlugin.measurements
    // ).map(([objectID, entities]) => ({ objectID, ...entities }));
    // measurementsArray.map((element, i) => {
    //   const item = props.distanceMeasurementPlugin.measurements[element.id];
    //   console.log("item", item);
    //   if (item.visible) item.destroy();
    // });
  };

  const handleObjectMeasurement = (coords) => {
    const pickResult = props.viewer.scene.pick({
      canvasPos: coords,
      pickSurface: true,
    });
    if (pickResult) {
      const { id, aabb } = pickResult.entity;
      if (objectMeasurementArray.length === 0) {
        console.log("0");
        objectMeasurementArray.push({ id, aabb });
        pickResult.entity.selected = true;
      } else if (objectMeasurementArray.length === 1) {
        console.log("1");
        objectMeasurementArray.push({ id, aabb });
        pickResult.entity.selected = true;

        const [aabb1, aabb2] = [objectMeasurementArray[0].aabb, aabb];
        createDistanceMeasurement(id, aabb1, aabb2);
      } else {
        console.log("44");
        console.log(objectMeasurementArray);
        const entity1 =
          props.viewer.scene.objects[objectMeasurementArray[0].id];
        const entity2 =
          props.viewer.scene.objects[objectMeasurementArray[1].id];
        entity1.selected = false;
        entity2.selected = false;
        objectMeasurementArray.pop();
        objectMeasurementArray.pop();
      }
    }
  };

  const createDistanceMeasurement = (id, aabb1, aabb2) => {
    const closestPoint1 = [
      Math.max(aabb1[0], Math.min(aabb2[0], aabb1[3])),
      Math.max(aabb1[1], Math.min(aabb2[1], aabb1[4])),
      Math.max(aabb1[2], Math.min(aabb2[2], aabb1[5])),
    ];
    const closestPoint2 = [
      Math.max(aabb2[0], Math.min(aabb1[0], aabb2[3])),
      Math.max(aabb2[1], Math.min(aabb1[1], aabb2[4])),
      Math.max(aabb2[2], Math.min(aabb1[2], aabb2[5])),
    ];

    props.distanceMeasurementPlugin.createMeasurement({
      id: "distanceMeasurement1",
      origin: {
        entity: props.viewer.scene.objects[objectMeasurementArray[0].id],
        worldPos: closestPoint1,
      },
      target: {
        entity: props.viewer.scene.objects[id],
        worldPos: closestPoint2,
      },
      visible: true,
      wireVisible: true,
    });
  };

  const handleElevationMeasurement = (coords, annotations) => {
    const pickResult = props.viewer.scene.pick({
      canvasPos: coords,
      pickSurface: true,
    });
    if (pickResult) {
      const aabb = props.viewer.scene.models.myModel.aabb;
      const minY = aabb[1];

      console.log("pickResult", pickResult.worldPos[1]);
      console.log("pickResult", pickResult.worldPos[1] - 1);
      console.log("minY", minY);
      annotations.clear();
      // const position = bottomOffset + pickResult.worldPos[1];
      const position = (pickResult.worldPos[1] - minY) * 0.3061224489;

      annotations.createAnnotation({
        id: "myAnnotation",
        worldPos: [
          pickResult.worldPos[0],
          pickResult.worldPos[1] - 0.4,
          pickResult.worldPos[2],
        ],
        // worldPos: pickResult.worldPos,
        occludable: true,
        markerShown: true,
        labelShown: true,
        values: { glyph: `${position.toFixed(2)}m` },
      });

      setElevationAnnotation(annotations);
    }
  };

  return (
    <>
      <div
        className={
          props.measureBtn
            ? "measurement-toolbar visible-show"
            : "measurement-toolbar visible-hide"
        }
      >
        <div>
          <button className="custom-button measure-btn-width">
            Straight Line
          </button>
          <button
            className="custom-button measure-btn-width"
            onClick={measureTwoPoints}
          >
            Two Points
          </button>
        </div>
        <div>
          <button
            className="custom-button measure-btn-width"
            onClick={measureTwoObjects}
          >
            Two Objects
          </button>
          <button
            className="custom-button measure-btn-width"
            onClick={measureElevation}
          >
            Elevation
          </button>
        </div>
      </div>
      <div className="search-sets-tab">
        <Tab
          isOpen={props.measureBtn}
          onClose={() => {}}
          tabName="Measurement Tab"
        >
          <ul className="search-sets-tab-ul">
            {measurementNameArray.map((_, i) => (
              <li className="search-sets-tab-li-item" key={i}>
                <div>{_.name}</div>
              </li>
            ))}
          </ul>
        </Tab>
        <Modal isOpen={isModalOpen}>
          <div>
            <div className="modal-header">
              <label>New Search Set</label>
              <span className="modal-close" onClick={handleClose}>
                X
              </span>
            </div>
            <div className="modal-body">
              <h4>Measurement Name</h4>
              <input
                value={measurementName}
                onChange={(e) => setMeasurementName(e.target.value)}
              />
              <button className="custom-button" onClick={saveMeasurementName}>
                Save
              </button>
            </div>
          </div>
        </Modal>
      </div>
    </>
  );
};

const mapStateToProps = (state) => ({
  viewer: state.xeokitReducer.viewer,
  measureBtn: state.xeokitReducer.measureBtn,
  distanceMeasurementsMouse: state.xeokitReducer.distanceMeasurementsMouse,
  distanceMeasurementArray: state.xeokitReducer.distanceMeasurementArray,
  distanceMeasurementPlugin: state.xeokitReducer.distanceMeasurementPlugin,
});

const mapDispatchToProps = {
  getMeasurementMouseControlInstantAction,
  saveDistanceMeasurementArrayAction,
  saveDistanceMeasurementPluginAction,
  seSelectEntityStatusAction,
};

export default connect(mapStateToProps, mapDispatchToProps)(Measure);
