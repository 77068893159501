import { act } from "react";
import { Constants } from "../constant/constants";
import { saveSectionPlanceCanvas } from "../action/xeokitAction";

const initialState = {
  isSearchEntityActive: false,
  searchEntityObject: {
    entityName: null,
    entityValue: null,
    entityColor: null,
    entityTransparency: null,
  },
  viewer: null,
  prevViewer: null,
  sectionCutBtn: null,
  resetSectionPlaneBtn: null,
  mouseOnClickValue: null,
  measureBtn: null,
  setSelectEntityStatus: null,
  distanceMeasurementsMouse: null,
  addToClashDetectionFromView: null,
  addToClashDetectionNormalClick: null,
  selectContextMenuBtnStatus: false,
  distanceMeasurementPlugin: null,
  distanceMeasurementArray: [],
  sectionPlanePlugin: null,
  saveViewerArray: [],
  setThumbnailOpacity: null,
  model: null,
  metaData: null,
  setModelLoadFlage: false,
  bcfViewPoint: null,
  annotationPlugin: null,
  measurementPlugin: null,
  sectionPlaneCanvas: null,
};
export function xeokitReducer(state = initialState, action) {
  switch (action.type) {
    case Constants.GET_VIEWER_INPUT_ONCLICK_INSTANT: {
      return { ...state, mouseOnClickValue: action.payload };
    }
    case Constants.GET_MEASUREMENT_MOUSE_CONTROL_INSTANT: {
      return { ...state, distanceMeasurementsMouse: action.payload };
    }

    case Constants.SAVE_VIEWER: {
      return { ...state, viewer: action.payload };
    }
    case Constants.SET_SECTIONCUT_BTN_STATUS: {
      return {
        ...state,
        sectionCutBtn: action.payload,
      };
    }
    case Constants.SET_MEASUREMENT_BTN_STATUS: {
      console.log("ere", action.payload);
      return {
        ...state,
        measureBtn: action.payload,
      };
    }
    case Constants.SET_SELECTENTITY_STATUS: {
      console.log("entitystatus", action.payload);
      return {
        ...state,
        setSelectEntityStatus: action.payload,
      };
    }
    case Constants.SET_RESET_SECTIONPLANE_BTN_STATUS: {
      return {
        ...state,
        resetSectionPlaneBtn: action.payload,
      };
    }
    case Constants.FIND_MODEL: {
      let flag;
      if (state.isSearchEntityActive) {
        flag = false;
      } else flag = true;

      console.log("findmodelreducer", action.payload);
      const temp = {};
      temp.entityName = action.payload.name;
      temp.entityValue = action.payload.value;
      temp.entityColor = action.payload.color;
      temp.entityTransparency = action.payload.transparency;
      return {
        ...state,
        searchEntityObject: temp,
        isSearchEntityActive: flag,
      };
    }
    case Constants.ADD_TO_CLASHDETECTION_LIST_FROM_VIEW: {
      let flag;
      if (state.selectContextMenuBtnStatus) {
        flag = false;
      } else flag = true;

      console.log("flag", flag);
      return {
        ...state,
        addToClashDetectionFromView: action.payload,
        selectContextMenuBtnStatus: flag,
      };
    }
    case Constants.ADD_TO_CLASHDETECTION_LIST_NORMAL_CLICK: {
      console.log("normalclickreducer", action.payload);
      return {
        ...state,
        addToClashDetectionNormalClick: action.payload,
      };
    }
    case Constants.SAVE_DISTANCEMEASUREMENT_PLUGIN: {
      return {
        ...state,
        distanceMeasurementPlugin: action.payload,
      };
    }
    case Constants.SAVE_DISTANCEMEASUREMENT_ARRAY: {
      return {
        ...state,
        distanceMeasurementArray: action.payload,
      };
    }
    case Constants.SAVE_SECTIONPLANE_PLUGIN: {
      console.log("sectionReducer", action.payload);
      return {
        ...state,
        sectionPlanePlugin: action.payload,
      };
    }
    case Constants.SAVE_CURRENT_VIEWER: {
      const temp = state.saveViewerArray;
      temp.push(action.payload);

      return {
        ...state,
        saveViewerArray: temp,
      };
    }
    case Constants.SET_THUMBNAIL_OPACITY: {
      let flag;
      if (state.setThumbnailOpacity) {
        flag = false;
      } else flag = true;
      return {
        ...state,
        setThumbnailOpacity: flag,
      };
    }
    case Constants.SAVE_METADATA: {
      console.log(
        "++++++++++++++++++++++METADATA++++++++++++++++++++++++++++++++++++++++",
        action.payload
      );
      return {
        ...state,
        metaData: action.payload,
      };
    }
    case Constants.SAVE_BCFVIEWPOINT: {
      console.log("reducer", action.payload);
      return {
        ...state,
        bcfViewPoint: action.payload,
      };
    }
    case Constants.SAVE_ANNOTATIONPLUGIN: {
      console.log("reducer", action.payload);
      return {
        ...state,
        annotationPlugin: action.payload,
      };
    }
    case Constants.SAVE_MEASUREMENTPLUGIN: {
      console.log("reducer", action.payload);
      return {
        ...state,
        measurementPlugin: action.payload,
      };
    }
    case Constants.SAVE_SECTIONPLANECANVAS: {
      console.log("reducer", action.payload);
      return {
        ...state,
        saveSectionPlanceCanvas: action.payload,
      };
    }
    default:
      return state;
  }
}
