import React, { useState, useEffect } from "react";
import { connect } from "react-redux";

import Tab from "../../components/Tab/Tab";

import { ImagePlane } from "https://cdn.jsdelivr.net/npm/@xeokit/xeokit-sdk/dist/xeokit-sdk.es.min.js";

import "./ImagePlane.css";

import { setImagePlaneStatusAction } from "../../action/xeokitAction";

import {
  apiHeader,
  imagePlaneUrl,
  BaseURL,
  recordsLimit,
} from "../../config/apiUrl";
import { Get, Patch } from "../../axios/axios";
import { Constants } from "../../constant/constants";

const ImagePlaneTab = (props) => {
  const [showList, setShowList] = useState([]);

  const [isLoading, setIsLoading] = useState(false);
  const [page, setPage] = useState(1);
  const [search, setSearch] = useState("");
  const [totalPages, setTotalPages] = useState();

  const getAllListing = async (pg = page) => {
    const url = BaseURL(
      `imagePlanes/projects/${props.projectId}?page=${pg}&limit=${recordsLimit}&search=${search}`
    );
    setIsLoading(true);
    const response = await Get(url, props.token);
    if (response !== undefined) {
      console.log("response", response);
      setShowList(response.data?.imagePlanes);
      setTotalPages(response?.data?.totalResults);
    }
    setIsLoading(false);
  };

  useEffect(() => {
    // getAllListing();
  }, []);

  useEffect(() => {
    getAllListing();
  }, [props.imagePlaneTabStatus]);

  const closeTab = () => {
    props.setImagePlaneStatusAction(false);
  };

  const [position, setPosition] = useState(50);
  const [imagePlane, setImagePlane] = useState();
  const [opacity, setOpacity] = useState(0.5);

  // Add this function to parse image metadata
  async function getImageMetadata(imageURL) {
    try {
      const response = await fetch(imageURL);
      const arrayBuffer = await response.arrayBuffer();
      const bytes = new Uint8Array(arrayBuffer);

      // Skip PNG signature (8 bytes)
      let offset = 8;

      while (offset < bytes.length) {
        // Read chunk length (4 bytes)
        const length =
          (bytes[offset] << 24) |
          (bytes[offset + 1] << 16) |
          (bytes[offset + 2] << 8) |
          bytes[offset + 3];
        offset += 4;

        // Read chunk type (4 bytes)
        const type = String.fromCharCode(
          bytes[offset],
          bytes[offset + 1],
          bytes[offset + 2],
          bytes[offset + 3]
        );
        offset += 4;

        // If this is a tEXt chunk
        if (type === "tEXt") {
          // Read the keyword until null byte
          let keywordEnd = offset;
          while (bytes[keywordEnd] !== 0 && keywordEnd < offset + length) {
            keywordEnd++;
          }

          // Skip the null separator
          keywordEnd++;

          // Read the text content
          const textContent = new TextDecoder().decode(
            bytes.slice(keywordEnd, offset + length)
          );

          try {
            const metadataJson = JSON.parse(textContent);
            console.log("Found metadata:", metadataJson);
            return metadataJson;
          } catch (e) {
            console.log("Found text but not valid JSON:", textContent);
          }
        }

        // Skip chunk data and CRC
        offset += length + 4;
      }

      throw new Error("No metadata found in image");
    } catch (error) {
      console.error("Error in getImageMetadata:", error);
      throw error;
    }
  }

  // Add image selection parameters
  const imageParams = {
    selectedImage: "",
    availableImages: [],
  };

  // Add state management for images
  const imageState = Object.seal({
    currentImage: null,
    loadedImages: new Map(),
    lastPosition: null,
    lastScale: null,
    imagePlane: null,
    gui: null,
    imagePlaneFolder: null,
    imagePlanes: new Map(),
    availableOptions: {},
    activeImagePlane: null,
    // Add these new properties
    planeOffsets: new Map(), // Store offset values for each plane
    originalPositions: new Map(), // Store original positions for each plane
  });

  // At the top level, outside of any function, add:
  const imageControls = {
    selectedImage: "",
    toString: function () {
      return `ImageControls(selectedImage: ${this.selectedImage})`;
    },
  };

  // Add this helper function at the top level to calculate cross product
  function crossProduct(a, b) {
    return [
      a[1] * b[2] - a[2] * b[1],
      a[2] * b[0] - a[0] * b[2],
      a[0] * b[1] - a[1] * b[0],
    ];
  }

  // Add the updateImagePlane function
  async function updateImagePlane(imageUrl, metadata) {
    try {
      const planeKey = metadata.ViewName || "unnamed";
      console.log("Creating/updating plane for:", planeKey);

      // Initialize default values
      let origin = [0, 0, 0];
      let upDirection = [0, 0, 1];
      let rightDirection = [-1, 0, 0];
      let dir = [0, -1, 0];
      let size = 290; // Default size

      // Parse metadata if available
      if (metadata && metadata.MinXYZStr && metadata.MaxXYZStr) {
        if (typeof metadata.MinXYZStr === "string") {
          // Parse coordinates
          const coords = metadata.CenterPoint.split(",").map(Number);
          const minCoords = metadata.MinXYZStr.split(",").map(Number);
          const maxCoords = metadata.MaxXYZStr.split(",").map(Number);

          // Calculate dimensions
          const width = Math.abs(maxCoords[0] - minCoords[0]);
          const height = Math.abs(maxCoords[2] - minCoords[2]);

          // Calculate scale factor based on the actual dimensions
          const baseSize = 100;
          const scaleFactor = Math.max(width, height) / baseSize;
          size = baseSize * scaleFactor;

          // Transform coordinates to match our coordinate system
          origin = [-coords[0], coords[2], coords[1]];
          const upCoords = metadata.UpDirection.split(",").map(Number);
          const rightCoords = metadata.RightDirection.split(",").map(Number);

          upDirection = [upCoords[0], upCoords[1], upCoords[2]];
          rightDirection = [rightCoords[0], rightCoords[1], rightCoords[2]];

          const rawDir = crossProduct(rightDirection, upDirection);
          dir = [-rawDir[0], -rawDir[2], -rawDir[1]];

          console.log("Calculated dimensions:", {
            width,
            height,
            scaleFactor,
            size,
            minCoords,
            maxCoords,
          });
        }
      }

      // Create or update image plane
      if (!imageState.imagePlanes.has(planeKey)) {
        console.log("Creating new plane for:", planeKey);
        const newPlane = new ImagePlane(props.viewer.scene, {
          visible: true,
          gridVisible: false,
          size: size,
          position: origin,
          up: upDirection,
          dir: dir,
          opacity: opacity,
          collidable: true, // ImagePlane does not contribute to Scene boundary
          clippable: true, // ImagePlane can be clipped by SectionPlanes
          pickable: true,
        });

        setImagePlane(newPlane);

        imageState.imagePlanes.set(planeKey, newPlane);
        imageState.imagePlane = newPlane;

        // Store original position using planeKey
        imageState.originalPositions.set(planeKey, [...origin]);
        imageState.planeOffsets.set(planeKey, 0);
      } else {
        console.log("Updating existing plane for:", planeKey);
        imageState.imagePlane = imageState.imagePlanes.get(planeKey);
        imageState.imagePlane.position = origin;
        imageState.imagePlane.size = size;
        imageState.imagePlane.up = upDirection;
        imageState.imagePlane.dir = dir;
        imageState.imagePlane.visible = true;

        // Update original position if not already stored
        if (!imageState.originalPositions.has(planeKey)) {
          imageState.originalPositions.set(planeKey, [...origin]);
          imageState.planeOffsets.set(planeKey, 0);
        }
      }

      // Load the image if needed
      if (!imageState.imagePlane.image) {
        console.log("Loading image for plane:", planeKey);
        const img = new Image();
        img.crossOrigin = "anonymous";
        await new Promise((resolve, reject) => {
          img.onload = () => {
            // Create a canvas to flip the image
            const canvas = document.createElement("canvas");
            canvas.width = img.width;
            canvas.height = img.height;
            const ctx = canvas.getContext("2d");

            // Save context state
            ctx.save();

            // Flip the context
            ctx.translate(canvas.width / 2, canvas.height / 2);
            ctx.rotate(Math.PI);
            ctx.translate(-canvas.width / 2, -canvas.height / 2);

            // Draw the image
            ctx.drawImage(img, 0, 0);

            // Restore context state
            ctx.restore();

            // Create new image from canvas
            const flippedImg = new Image();
            flippedImg.onload = () => {
              imageState.imagePlane.image = flippedImg;
              props.viewer.scene.render(true);
              resolve();
            };
            flippedImg.src = canvas.toDataURL();
          };
          img.onerror = reject;
          img.src = imageUrl;
        });
      }

      props.viewer.scene.render(true); // Force scene update
      console.log("Plane update complete for:", planeKey);
    } catch (error) {
      console.error("Error in updateImagePlane:", error);
      throw error;
    }
  }

  const handleButton = async (url) => {
    if (imagePlane) imagePlane.destroy();
    setPosition(50);
    setOpacity(0.5);
    const metadata = await getImageMetadata(imagePlaneUrl(url));
    await updateImagePlane(imagePlaneUrl(url), metadata);
  };

  const onChangeAxisZ = (e) => {
    setPosition(e.target.value);
    if (!imagePlane) return;
    const oldPosition = imagePlane.position;
    const dir = imagePlane.dir;

    imagePlane.position = [
      e.target.value > position
        ? oldPosition[0] + 0.5 * dir[0]
        : oldPosition[0] - 0.5 * dir[0],
      e.target.value > position
        ? oldPosition[1] + 0.5 * dir[1]
        : oldPosition[1] - 0.5 * dir[1],
      e.target.value > position
        ? oldPosition[2] + 0.5 * dir[2]
        : oldPosition[2] - 0.5 * dir[2],
    ];
  };

  const onChangeOpacity = (e) => {
    setOpacity(e.target.value);
    if (!imagePlane) return;
    imagePlane.opacity = e.target.value;
  };

  return (
    <div className="property-tab">
      <Tab
        isOpen={props.imagePlaneTabStatus}
        onClose={closeTab}
        tabName={"ImagePlane Tab"}
      >
        {showList?.map((list, index) => (
          <button
            className="imagePlane-button "
            type="button"
            onClick={() => handleButton(list.filename)}
          >
            {list.originalname}
          </button>
        ))}

        <div class="slider-container">
          <div class="slider-label">Opacity</div>
          <input
            className="slider"
            type="range"
            id="opacity"
            name="opacity"
            value={opacity}
            onChange={(e) => onChangeOpacity(e)}
            min="0"
            max="1"
            step={0.1}
          />
          <div class="slider-value" id="sliderValue">
            {opacity}
          </div>
        </div>

        <div class="slider-container">
          <div class="slider-label">Offset</div>
          <input
            className="slider"
            type="range"
            id="offset"
            name="offset"
            value={position}
            onChange={(e) => onChangeAxisZ(e)}
            min="0"
            max="100"
          />
          <div class="slider-value" id="sliderValue">
            {position}
          </div>
        </div>
      </Tab>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    viewer: state.xeokitReducer.viewer,
    imagePlaneTabStatus: state.ImagePlaneReducer.imagePlaneTabStatus,
    projectId: state.ProjectReducer.projectId,
    token: state.AuthReducer.token,
  };
};

const mapDispatchToProps = {
  setImagePlaneStatusAction,
};

export default connect(mapStateToProps, mapDispatchToProps)(ImagePlaneTab);
