import React from "react";
import { connect } from "react-redux";
import { Navigate } from "react-router-dom";

const ProtectedRouter = (props) => {
  const isAuthenticated = props.isLogin;

  return isAuthenticated ? props.element : <Navigate replace to="/login" />;
};

const mapStateToProps = (state) => {
  return {
    isLogin: state.AuthReducer.isLogin,
  };
};

export default connect(mapStateToProps, null)(ProtectedRouter);
