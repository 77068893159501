import { Constants } from "../constant/constants";

const initialState = {
  setSearchTabStatus: false,
};
export function SearchReducer(state = initialState, action) {
  switch (action.type) {
    case Constants.SET_SEARCHTAB_STATUS: {
      console.log("searchreducer");
      return { ...state, setSearchTabStatus: action.payload };
    }
    default:
      return state;
  }
}
