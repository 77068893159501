import { Constants } from "../constant/constants";

const initialState = {
  isAppearenceTemplateTabOpen: false,
  appearenceTemplateList: [],
  isSearchAppearenceTemplateList: false,
  selectedAppearenceTemplateList: null,
};
export function AppearenceTemplateReducer(state = initialState, action) {
  switch (action.type) {
    case Constants.SET_APPEARENCETEMPLATETAB_STATUS: {
      console.log("reducer");
      return { ...state, isAppearenceTemplateTabOpen: action.payload };
    }
    case Constants.SAVE_APPEARENCETEMPLATE_LIST: {
      console.log("????");
      return { ...state, appearenceTemplateList: action.payload };
    }
    case Constants.SEARCH_APPEARENCETEMPLATE_LIST: {
      let flag;
      if (state.isSearchAppearenceTemplateList) {
        flag = false;
      } else flag = true;
      console.log(flag);
      return {
        ...state,
        selectedAppearenceTemplateList: action.payload,
        isSearchAppearenceTemplateList: flag,
      };
    }
    default:
      return state;
  }
}
