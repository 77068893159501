import React from "react";

import "./Header.css";
// import Logo from "../asset/icon.png";

const Header = () => {
  return (
    <div className="header">
      {/* <div className="logo">
            <img src={Logo} width="30px" alt="Logo"></img>
          </div> */}
      <div>Project</div>
      <div>Edit Preferences</div>
      <div>Sync</div>
      <div>Share</div>
      <div>Help</div>
      <div>Nestor S</div>
    </div>
  );
};

export default Header;
