import React, { useState, useEffect } from "react";
import { connect } from "react-redux";

import Tab from "../../components/Tab/Tab";

import "./ClashTestTab.css";

import { setClashTestTabStatusAction } from "../../action/xeokitAction";

import PNG_BINOCULAR from "../../asset/binocular.png";

const ClashTestTab = ({
  clashDetectionList,
  clashDetectionListFlag,
  setClashTestTabStatus,
  setClashTestTabStatusAction,
}) => {
  const [showList, setShowList] = useState([]);

  useEffect(() => {
    setShowList(clashDetectionList);
  }, [clashDetectionListFlag]);

  const closeTab = () => {
    setClashTestTabStatusAction(false);
  };

  return (
    <div className="search-sets-tab">
      <Tab
        isOpen={setClashTestTabStatus}
        onClose={closeTab}
        tabName={"Clash Test Tab"}
      >
        <ul className="search-sets-tab-ul">
          {showList &&
            showList.map((item, index) => (
              <li
                className="search-sets-tab-li-item"
                key={index}
                value={item.clashDetectionName}
              >
                <div>
                  <img src={PNG_BINOCULAR} alt="binocular" />
                </div>
                <div>{item.clashDetectionName}</div>
              </li>
            ))}
        </ul>
      </Tab>
    </div>
  );
};
const mapStateToProps = (state) => ({
  setClashTestTabStatus: state.ClashDetectionReducer.setClashTestTabStatus,
  clashDetectionList: state.ClashDetectionReducer.clashDetectionList,
  clashDetectionListFlag: state.ClashDetectionReducer.clashDetectionListFlag,
});

const mapDispatchToProps = {
  setClashTestTabStatusAction,
};

export default connect(mapStateToProps, mapDispatchToProps)(ClashTestTab);
