import { Constants } from "../constant/constants";

const initialState = {
  setAppearenceProfileTabStatus: false,
  appearenceProfileList: [],
  appearanceProfileListStatus: false,
};
export function AppearenceProfileReducer(state = initialState, action) {
  switch (action.type) {
    case Constants.SET_APPEARENCEPROFILETAB_STATUS: {
      console.log("reduercer", action.payload);
      return { ...state, setAppearenceProfileTabStatus: action.payload };
    }
    case Constants.SAVE_APPEARENCEPROFILE_LIST: {
      let flag;
      if (state.appearanceProfileListStatus) {
        flag = false;
      } else flag = true;
      console.log("reducer", action.payload);
      return {
        ...state,
        appearenceProfileList: action.payload,
        appearanceProfileListStatus: flag,
      };
    }
    default:
      return state;
  }
}
