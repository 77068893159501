// import React, { useState } from "react";
// import { connect } from "react-redux";

// import { setModelUrlAction } from "../../action/xeokitAction";

// const FileUpload = (props) => {
//   //   const [file, setFile] = useState(null);

//   //   const onDrop = (acceptedFiles) => {
//   //     const file = acceptedFiles[0];
//   //     setFile(file);

//   //     const fileURL = URL.createObjectURL(file).slice(5);
//   //     // const fileURL = URL.createObjectURL(file);
//   //     const reader = new FileReader();

//   //     reader.onload = function (e) {
//   //       // Create a blob URL and use it as the src for xktLoader
//   //       const blobURL = URL.createObjectURL(new Blob([e.target.result])).slice(5);
//   //       props.setModelUrlAction(blobURL);
//   //     };

//   //     reader.readAsArrayBuffer(file);
//   //   };

//   //   const { getRootProps, getInputProps, isDragActive } = useDropzone({
//   //     onDrop,
//   //     accept: ".xkt", // Accept only .xkt files
//   //     multiple: false, // Allow only one file to be dropped
//   //   });

//   //   return (
//   //     <div style={styles.container}>
//   //       <div {...getRootProps()} style={styles.dropzone}>
//   //         <input {...getInputProps()} />
//   //         {isDragActive ? (
//   //           <p>Drop the .xkt file here...</p>
//   //         ) : (
//   //           <p>Drag & drop a .xkt file here, or click to select a file</p>
//   //         )}
//   //       </div>
//   //       {file && <p>File loaded: {file.name}</p>}
//   //     </div>
//   //   );

//   const [uploadProgress, setUploadProgress] = useState(0);
//   const [loading, setLoading] = useState(false);

//   const handleDrop = (event) => {
//     event.preventDefault();

//     const file = event.dataTransfer.files[0];

//     if (!file) {
//       console.error("No file dropped");
//       return;
//     }

//     // Check if the file has a .xkt extension
//     if (!file.name.toLowerCase().endsWith(".xkt")) {
//       alert("Only .xkt files are allowed");
//       return;
//     }

//     const reader = new FileReader();

//     // Reset progress when a new file is uploaded
//     setUploadProgress(0);
//     setLoading(true);

//     reader.onprogress = (event) => {
//       if (event.lengthComputable) {
//         const percentLoaded = Math.round((event.loaded / event.total) * 100);
//         setUploadProgress(percentLoaded);
//       }
//     };

//     reader.onloadstart = () => {
//       setUploadProgress(0); // Reset progress at the start
//     };

//     reader.onloadend = (e) => {
//       // Set a minimum delay for the progress bar to be visible
//       const minDelay = 500; // 500ms minimum delay
//       const elapsedTime = Date.now() - reader.onloadstartTime;
//       const delay = Math.max(0, minDelay - elapsedTime);

//       setTimeout(() => {
//         setUploadProgress(100); // Ensure progress is set to 100% on completion
//         setLoading(false); // Stop loading indicator
//         console.log("file", e.target.result);
//         props.setModelUrlAction(e.target.result);
//       }, delay);
//     };

//     // Store the start time for calculating elapsed time
//     reader.onloadstartTime = Date.now();

//     reader.readAsArrayBuffer(file);
//   };

//   // // Simulate progress
//   // const simulateProgress = () => {
//   //   if (uploadProgress < 100) {
//   //     setUploadProgress((prev) => Math.min(prev + 5, 100));
//   //     setTimeout(simulateProgress, 100);
//   //   }
//   // };

//   // simulateProgress();

//   const handleDragOver = (event) => {
//     event.preventDefault();
//   };

//   return (
//     <div
//       id="dropZone"
//       style={{
//         width: "100%",
//         height: "100vh",
//         border: "2px dashed #ddd",
//         display: "flex",
//         justifyContent: "center",
//         alignItems: "center",
//         textAlign: "center",
//         fontSize: "20px",
//         position: "relative",
//         background: "#1e1e1e", // Dark background for contrast
//         color: "#ecf0f1", // Light text color
//         overflow: "hidden",
//       }}
//       onDrop={handleDrop}
//       onDragOver={handleDragOver}
//     >
//       {loading && (
//         <div style={{ position: "relative", width: "80%", maxWidth: "600px" }}>
//           <div
//             style={{
//               position: "relative",
//               width: "100%",
//               height: "30px",
//               borderRadius: "15px",
//               backgroundColor: "#333",
//               boxShadow: "0 8px 15px rgba(0, 0, 0, 0.4)",
//               overflow: "hidden",
//               padding: "2px",
//               border: "1px solid #555",
//               transform: "perspective(500px) rotateX(10deg)", // 3D perspective effect
//             }}
//           >
//             <div
//               style={{
//                 height: "100%",
//                 width: `${uploadProgress}%`,
//                 borderRadius: "15px",
//                 background: "linear-gradient(90deg, #00bfff, #1e90ff)", // Blue gradient
//                 boxShadow: "0 0 15px rgba(0, 0, 0, 0.5)",
//                 position: "relative",
//                 transition: "width 0.5s ease-in-out",
//                 backgroundSize: "200% 100%", // Gradient size for animation
//                 animation: "progressAnimation 2s linear infinite", // Animation
//               }}
//             >
//               <span
//                 style={{
//                   position: "absolute",
//                   right: "15px",
//                   top: "50%",
//                   transform: "translateY(-50%)",
//                   color: "#fff",
//                   fontWeight: "bold",
//                   fontSize: "18px",
//                   textShadow: "1px 1px 3px rgba(0, 0, 0, 0.7)", // Text shadow
//                 }}
//               >
//                 {uploadProgress}%
//               </span>
//             </div>
//           </div>
//           <p
//             style={{ marginTop: "20px", fontSize: "20px", fontWeight: "bold" }}
//           >
//             Loading... {uploadProgress}%
//           </p>
//         </div>
//       )}

//       {!loading && uploadProgress === 0 && (
//         <p>Drag and drop your .xkt file here</p>
//       )}

//       {!loading && uploadProgress === 100 && <p>Model Loaded Successfully!</p>}
//     </div>
//   );
// };

// // Keyframes for the progress bar animation
// const styleSheet = document.styleSheets[0];
// const keyframes = `
// @keyframes progressAnimation {
//   0% { background-position: 0% 0%; }
//   50% { background-position: 100% 0%; }
//   100% { background-position: 0% 0%; }
// }
// `;
// styleSheet.insertRule(keyframes, styleSheet.cssRules.length);

// const mapDispatchToProps = {
//   setModelUrlAction,
// };

// export default connect(null, mapDispatchToProps)(FileUpload);

// import React, { useState } from "react";
// import { connect } from "react-redux";

// import { setModelUrlAction } from "../../action/xeokitAction";

// const FileUpload = (props) => {
//   const [uploadProgress, setUploadProgress] = useState(0);
//   const [loading, setLoading] = useState(false);

//   const handleDrop = (event) => {
//     event.preventDefault();
//     const droppedFiles = Array.from(event.dataTransfer.files);
//     let xktFile = null;
//     let jsonFile = null;

//     // Filter .xkt and .json files
//     droppedFiles.forEach((file) => {
//       if (file.name.toLowerCase().endsWith(".xkt")) {
//         xktFile = file;
//       } else if (file.name.toLowerCase().endsWith(".json")) {
//         jsonFile = file;
//       }
//     });

//     if (!xktFile || !jsonFile) {
//       alert("You must upload both .xkt and .json files together.");
//       return;
//     }

//     // Start the upload for both files
//     startUpload(xktFile, jsonFile);
//   };

//   const startUpload = (xktFile, jsonFile) => {
//     setLoading(true);
//     const totalSize = xktFile.size + jsonFile.size;
//     let loaded = 0;

//     const updateProgress = (event) => {
//       if (event.lengthComputable) {
//         loaded += event.loaded;
//         const progress = Math.round((loaded / totalSize) * 100);
//         setUploadProgress(progress);
//       }
//     };

//     // FileReader for each file
//     const readXKT = new Promise((resolve) => {
//       const reader = new FileReader();
//       reader.onprogress = updateProgress;
//       reader.onloadend = () => resolve(reader.result); // Resolve only the data
//       reader.readAsArrayBuffer(xktFile); // Read .xkt as ArrayBuffer
//     });

//     const readJSON = new Promise((resolve) => {
//       const reader = new FileReader();
//       reader.onprogress = updateProgress;
//       reader.onloadend = () => resolve(reader.result); // Resolve only the data
//       reader.readAsText(jsonFile); // Read .json as Text
//     });

//     // Wait for both files to be processed
//     Promise.all([readXKT, readJSON]).then(([xktData, jsonData]) => {
//       setLoading(false);
//       setUploadProgress(100);
//       // Call the callback with xktData and jsonData separately

//       const data = { xkt: xktData, json: jsonData };
//       props.setModelUrlAction(data);
//     });
//   };

//   const handleDragOver = (event) => {
//     event.preventDefault();
//   };

//   return (
//     <div
//       style={{
//         width: "100%",
//         height: "100vh",
//         border: "2px dashed #ddd",
//         display: "flex",
//         justifyContent: "center",
//         alignItems: "center",
//         textAlign: "center",
//         fontSize: "20px",
//         background: "#f0f0f0",
//         color: "#333",
//       }}
//       onDrop={handleDrop}
//       onDragOver={handleDragOver}
//     >
//       {loading ? (
//         <div style={{ width: "80%", maxWidth: "600px" }}>
//           <div
//             style={{
//               width: "100%",
//               height: "30px",
//               backgroundColor: "#ddd",
//               borderRadius: "5px",
//               overflow: "hidden",
//               boxShadow: "inset 0px 0px 5px rgba(0,0,0,0.2)",
//             }}
//           >
//             <div
//               style={{
//                 width: `${uploadProgress}%`,
//                 height: "100%",
//                 backgroundColor: "#4caf50",
//                 transition: "width 0.5s ease-in-out",
//               }}
//             ></div>
//           </div>
//           <p style={{ marginTop: "10px" }}>{uploadProgress}% Uploaded</p>
//         </div>
//       ) : (
//         <p>Drag and drop both .xkt and .json files here</p>
//       )}
//     </div>
//   );
// };

// const mapDispatchToProps = {
//   setModelUrlAction,
// };
// export default connect(null, mapDispatchToProps)(FileUpload);

// import React, { useState } from "react";
// import { connect } from "react-redux";

// import { setModelUrlAction } from "../../action/xeokitAction";

// const FileUpload = (props) => {
//   const [xktFile, setXktFile] = useState(null);
//   const [jsonFile, setJsonFile] = useState(null);
//   const [uploadProgress, setUploadProgress] = useState(0);
//   const [loading, setLoading] = useState(false);

//   const handleDropXKT = (event) => {
//     event.preventDefault();
//     const file = event.dataTransfer.files[0];

//     if (file && file.name.toLowerCase().endsWith(".xkt")) {
//       setXktFile(file);
//     } else {
//       alert("Only .xkt files are allowed!");
//     }
//   };

//   const handleDropJSON = (event) => {
//     event.preventDefault();
//     const file = event.dataTransfer.files[0];

//     if (file && file.name.toLowerCase().endsWith(".json")) {
//       setJsonFile(file);
//     } else {
//       alert("Only .json files are allowed!");
//     }
//   };

//   const handleCancelXKT = () => {
//     setXktFile(null);
//   };

//   const handleCancelJSON = () => {
//     setJsonFile(null);
//   };

//   const handleUpload = () => {
//     if (!xktFile || !jsonFile) {
//       alert("Please upload both .xkt and .json files before proceeding.");
//       return;
//     }

//     setLoading(true);
//     const totalSize = xktFile.size + jsonFile.size;
//     let loaded = 0;

//     const updateProgress = (event) => {
//       if (event.lengthComputable) {
//         loaded += event.loaded;
//         const progress = Math.round((loaded / totalSize) * 100);
//         setUploadProgress(progress);
//       }
//     };

//     const readXKT = new Promise((resolve) => {
//       const reader = new FileReader();
//       reader.onprogress = updateProgress;
//       reader.onloadend = () => resolve(reader.result);
//       reader.readAsArrayBuffer(xktFile);
//     });

//     const readJSON = new Promise((resolve) => {
//       const reader = new FileReader();
//       reader.onprogress = updateProgress;
//       reader.onloadend = () => resolve(reader.result);
//       reader.readAsText(jsonFile);
//     });

//     Promise.all([readXKT, readJSON]).then(([xktData, jsonData]) => {
//       setLoading(false);
//       setUploadProgress(100);
//       const data = { xkt: xktData, json: jsonData };
//       props.setModelUrlAction(data);
//     });
//   };

//   const handleDragOver = (event) => {
//     event.preventDefault();
//   };

//   return (
//     <div className="file-upload-container">
//       {/* Drop area for .xkt file */}
//       <div
//         className="drop-area xkt-drop"
//         onDrop={handleDropXKT}
//         onDragOver={handleDragOver}
//       >
//         {xktFile ? (
//           <div>
//             <p className="file-name">{xktFile.name}</p>
//             <button onClick={handleCancelXKT} className="cancel-button">
//               Cancel XKT
//             </button>
//           </div>
//         ) : (
//           <p className="drop-message">Drag and drop the .xkt file here</p>
//         )}
//       </div>

//       {/* Drop area for .json file */}
//       <div
//         className="drop-area json-drop"
//         onDrop={handleDropJSON}
//         onDragOver={handleDragOver}
//       >
//         {jsonFile ? (
//           <div>
//             <p className="file-name">{jsonFile.name}</p>
//             <button onClick={handleCancelJSON} className="cancel-button">
//               Cancel JSON
//             </button>
//           </div>
//         ) : (
//           <p className="drop-message">Drag and drop the .json file here</p>
//         )}
//       </div>

//       {/* Upload button */}
//       <div className="upload-button-container">
//         <button
//           onClick={handleUpload}
//           disabled={loading}
//           className="upload-button"
//         >
//           {loading ? "Uploading..." : "Upload Files"}
//         </button>
//       </div>

//       {/* Progress Bar */}
//       {loading && (
//         <div className="progress-bar-container">
//           <div className="progress-bar">
//             <div
//               className="progress-bar-fill"
//               style={{ width: `${uploadProgress}%` }}
//             ></div>
//             <div className="progress-bar-text">{uploadProgress}%</div>
//           </div>
//         </div>
//       )}
//     </div>
//   );
// };

// const mapDispatchToProps = {
//   setModelUrlAction,
// };
// export default connect(null, mapDispatchToProps)(FileUpload);

import React, { useState } from "react";
import { connect } from "react-redux";
// import { RotatingLines } from "react-loader-spinner";
// import { uploadModelAndJson } from "../../action/xeokitAction";
// import RotatingLines from "../../components/Spinner/Spinner";
// import RotatingDots from "../../components/Spinner/Spinner";
import "./FileUpload.css";

const FileUpload = (props) => {
  const [xktFile, setXktFile] = useState(null);
  const [jsonFile, setJsonFile] = useState(null);
  const [uploadProgress, setUploadProgress] = useState(0);
  const [loading, setLoading] = useState(false);
  const [totalSize, setTotalSize] = useState(0);
  const [loadedSize, setLoadedSize] = useState(0);

  const handleDropXKT = (event) => {
    event.preventDefault();
    const file = event.dataTransfer.files[0];

    if (file && file.name.toLowerCase().endsWith(".xkt")) {
      setXktFile(file);
    } else {
      alert("Only .xkt files are allowed!");
    }
  };

  const handleDropJSON = (event) => {
    event.preventDefault();
    const file = event.dataTransfer.files[0];

    if (file && file.name.toLowerCase().endsWith(".json")) {
      setJsonFile(file);
    } else {
      alert("Only .json files are allowed!");
    }
  };

  const handleCancelXKT = () => {
    setXktFile(null);
  };

  const handleCancelJSON = () => {
    setJsonFile(null);
  };

  const handleUpload = () => {
    if (!xktFile || !jsonFile) {
      alert("Please upload both .xkt and .json files before proceeding.");
      return;
    }

    setLoading(true);
    const totalSize = xktFile.size + jsonFile.size;
    let loadedSize = 0;

    const updateProgress = (event, fileSize) => {
      if (event.lengthComputable) {
        loadedSize += event.loaded;
        const progress = Math.round((loadedSize / totalSize) * 100);
        setUploadProgress(progress);
      }
    };

    const readFile = (file) => {
      return new Promise((resolve) => {
        const reader = new FileReader();
        reader.onprogress = (event) => updateProgress(event, file.size);
        reader.onloadend = () => resolve(reader.result);
        if (file.name.toLowerCase().endsWith(".xkt")) {
          reader.readAsArrayBuffer(file);
        } else {
          reader.readAsText(file);
        }
      });
    };

    const readXKT = readFile(xktFile);
    const readJSON = readFile(jsonFile);

    Promise.all([readXKT, readJSON]).then(([xktData, jsonData]) => {
      setLoading(false);
      setUploadProgress(100);
      const data = { xkt: xktData, json: jsonData };
      // props.uploadModelAndJson(data);
    });
  };

  const handleDragOver = (event) => {
    event.preventDefault();
  };

  return (
    <div className="file-upload-container">
      <div
        className="drop-area xkt-drop"
        onDrop={handleDropXKT}
        onDragOver={handleDragOver}
      >
        {xktFile ? (
          <div>
            <p className="file-name">{xktFile.name}</p>
            <button onClick={handleCancelXKT} className="cancel-button">
              Cancel XKT
            </button>
          </div>
        ) : (
          <p className="drop-message">Drag and drop the .xkt file here</p>
        )}
      </div>

      <div
        className="drop-area json-drop"
        onDrop={handleDropJSON}
        onDragOver={handleDragOver}
      >
        {jsonFile ? (
          <div>
            <p className="file-name">{jsonFile.name}</p>
            <button onClick={handleCancelJSON} className="cancel-button">
              Cancel JSON
            </button>
          </div>
        ) : (
          <p className="drop-message">Drag and drop the .json file here</p>
        )}
      </div>

      <div className="upload-button-container">
        <button
          onClick={handleUpload}
          disabled={loading}
          className="upload-button"
        >
          {loading ? "Uploading..." : "Upload Files"}
        </button>
      </div>

      {loading && (
        <div className="progress-bar-container">
          <div className="progress-bar">
            <div
              className="progress-bar-fill"
              style={{ width: `${uploadProgress}%` }}
            ></div>
            <div className="progress-bar-text">{uploadProgress}%</div>
          </div>
        </div>
      )}

      {/* <RotatingDots
        width="50"
        strokeColor="red"
        strokeWidth="5"
        animationDuration="2"
        visible="true"
      /> */}
      {/* <div>
        <RotatingLines
          visible={true}
          height={420}
          width={420}
          color="orange"
          strokeWidth={1}
          strokeLength={3} // Customize the stroke length here
          animationDuration={1.5}
        />
      </div> */}
      {/* <RotatingLines
        visible={true}
        height="96"
        width="96"
        color="red"
        strokeWidth="1"
        animationDuration="0.75"
        ariaLabel="rotating-lines-loading"
        wrapperStyle={{ color: "red" }}
        wrapperClass=""
      /> */}
    </div>
  );
};

const mapDispatchToProps = {
  // uploadModelAndJson,
};
export default connect(null, mapDispatchToProps)(FileUpload);
