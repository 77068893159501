import React from "react";
import "./Footer.css";

import CreateViewpoint from "../../asset/createviewpoint.png";
import Undo from "../../asset/undo.png";
import Redo from "../../asset/redo.png";
import ShowAll from "../../asset/showall.png";
import IsolateTrans from "../../asset/isolatetrans.png";
import Isolate from "../../asset/isolate.png";
import Hide from "../../asset/hide.png";
import ToggleSection from "../../asset/togglesection.png";
import ToggleSheet from "../../asset/togglesheet.png";
import ShowHideTemp from "../../asset/showhidetemp.png";
import ToggleGrid from "../../asset/togglegrid.png";
import ToggleIssue from "../../asset/toggleissuepoint.png";
import LastUseAppear from "../../asset/lastuseapper.png";
import HomeViewpoint from "../../asset/homeviewpoint.png";
import Camera from "../../asset/camera.png";
import ViewLog from "../../asset/viewlog.png";

const Footer = () => {
  return (
    <>
      <div className="footer">
        <div className="footer-row1">
          <div>
            <img src={CreateViewpoint} alt="CreateViewPoint" />
          </div>
        </div>
        <div className="footer-row2">
          <div>
            <img src={Undo} alt="Undo" />
          </div>
          <div>
            <img src={Redo} alt="Redo" />
          </div>
        </div>
        <div className="footer-row3"></div>
        <div className="footer-row4">
          <div>
            <img src={ShowAll} alt="ShowAll" />
          </div>
          <div>
            <img src={IsolateTrans} alt="IsolateTrans" />
          </div>
          <div>
            <img src={Isolate} alt="Isolate" />
          </div>
          <div>
            <img src={Hide} alt="Hide" />
          </div>
        </div>
        <div className="footer-row5">
          <div>
            <img src={ToggleSection} alt="ToggleSection" />
          </div>
          <div>
            <img src={ToggleSheet} alt="ToggleSheet" />
          </div>
          <div>
            <img src={ShowHideTemp} alt="ShowHideTemp" />
          </div>
          <div>
            <img src={ToggleGrid} alt="ToggleGrid" />
          </div>
          <div>
            <img src={ToggleIssue} alt="ToggleIssue" />
          </div>
        </div>
        <div className="footer-row6">
          <div>
            <img src={LastUseAppear} alt="LastUseAppear" />
          </div>
          <div>
            <img src={HomeViewpoint} alt="HomeViewPoint" />
          </div>
          <div>
            <img src={Camera} alt="Camera" />
          </div>
        </div>
        <div className="footer-row7">
          <div>
            <img src={ViewLog} alt="ViewLog" />
          </div>
        </div>
      </div>
    </>
  );
};

export default Footer;
