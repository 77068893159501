import React, { useState } from "react";
import { connect } from "react-redux";

import {
  setPropertyTabStatusAction,
  setSearchTabStatusAction,
  setSelectedPropertyData,
} from "../../action/xeokitAction";

import ContextMenu from "../../components/ContextMenu/ContextMenu";
import Tab from "../../components/Tab/Tab";

import "./PropertyTab.css";
const PropertyTab = (props) => {
  const [selectedKey, setSelectedKey] = useState(null);
  const [selectedValue, setSelectedValue] = useState(null);

  const closeTab = () => {
    props.setPropertyTabStatusAction();
    props.setSearchTabStatusAction(false);
  };

  const [contextMenuVisible, setContextMenuVisible] = useState(false);
  const [contextMenuItems, setContextMenuItems] = useState([]);
  const [contextMenuPosition, setContextMenuPosition] = useState({
    x: 0,
    y: 0,
  });

  const handleContextMenu = (event) => {
    event.preventDefault();
    setContextMenuItems([
      {
        label: "Create New Search",
        onClick: () => {
          props.setSelectedPropertyData({
            selectedKey: selectedKey,
            selectedValue: selectedValue,
          });
          if (!props.setSearchTabStatus) props.setSearchTabStatusAction(true);
        },
      },
      { label: "Close", onClick: () => closeContextMenu() },
    ]);
    console.log("x", event);
    console.log("y", event);
    setContextMenuPosition({ x: event.pageX, y: event.pageY });
    setContextMenuVisible(true);
  };

  const closeContextMenu = () => {
    setContextMenuVisible(false);
  };

  const handleSelectList = (key, value) => {
    setSelectedKey(key);
    setSelectedValue(value);
  };

  return (
    <div className="property-tab" onContextMenu={handleContextMenu}>
      <Tab
        isOpen={props.propertyFlag}
        onClose={closeTab}
        tabName={"Properties"}
      >
        <table>
          <tbody>
            <tr>
              <th>Property Name</th>
              <th>Value</th>
            </tr>
            {Object.entries(props.properties).map(([key, value]) => (
              <tr
                className="property-row"
                onMouseUp={() => handleSelectList(key, value)}
              >
                <td>{key}</td>
                <td>{value}</td>
              </tr>
            ))}
          </tbody>
        </table>
      </Tab>
      <div>
        {contextMenuVisible && (
          <ContextMenu
            items={contextMenuItems}
            position={contextMenuPosition}
            onClose={closeContextMenu}
          />
        )}
      </div>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    propertyFlag: state.PropertyReducer.propertyFlag,
    setSearchTabStatus: state.SearchReducer.setSearchTabStatus,
    properties: state.PropertyReducer.properties,
  };
};

const mapDispatchToProps = {
  setPropertyTabStatusAction,
  setSearchTabStatusAction,
  setSelectedPropertyData,
};

export default connect(mapStateToProps, mapDispatchToProps)(PropertyTab);
