import React from "react";
import { connect } from "react-redux";
import { Navigate } from "react-router-dom";

const BeforeLoginRoute = (props) => {
  const isAuthenticated = props.isLogin;
  console.log("isLogin", props.isLogin);
  return !isAuthenticated ? props.element : <Navigate replace to="/projects" />;
};

const mapStateToProps = (state) => {
  return {
    isLogin: state.AuthReducer.isLogin,
  };
};

export default connect(mapStateToProps, null)(BeforeLoginRoute);
