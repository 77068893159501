import { Constants } from "../constant/constants";

const initialState = {
  projectName: "",
  projectId: "",
  model: "",
  json: "",
  metaData: null,
};
export function ProjectReducer(state = initialState, action) {
  switch (action.type) {
    case Constants.SAVE_PROJECT: {
      return {
        ...state,
        projectName: action.payload?.projectName,
        projectId: action.payload?.projectId,
        model: action.payload?.model,
        json: action.payload?.json,
        metaData: action.payload?.metaData,
      };
    }
    default:
      return state;
  }
}
