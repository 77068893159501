import React, { useState, useEffect } from "react";
import { connect } from "react-redux";

import ContextMenu from "../../components/ContextMenu/ContextMenu";
import Tab from "../../components/Tab/Tab";

import "./SearchSetsTab.css";

import {
  setEditSearchTabStatusAction,
  setPropertyTabStatusAction,
  setSearchSaveTabStatusAction,
  setSearchTabStatusAction,
  setEditSearchDataAction,
  findModel,
} from "../../action/xeokitAction";

import { apiHeader, BaseURL, recordsLimit } from "../../config/apiUrl";
import { Get, Patch } from "../../axios/axios";
import { Constants } from "../../constant/constants";

import PNG_BINOCULAR from "../../asset/binocular.png";

const SearchSetsTab = (props) => {
  const [showList, setShowList] = useState([]);
  const [selectedList, setSelectedList] = useState(null);

  const [contextMenuVisible, setContextMenuVisible] = useState(false);
  const [contextMenuItems, setContextMenuItems] = useState([]);
  const [contextMenuPosition, setContextMenuPosition] = useState({
    x: 0,
    y: 0,
  });
  const [isLoading, setIsLoading] = useState(false);
  const [page, setPage] = useState(1);
  const [search, setSearch] = useState("");
  const [totalPages, setTotalPages] = useState();

  const getAllListing = async (pg = page) => {
    const url = BaseURL(
      `searches/projects/${props.projectId}?page=${pg}&limit=${recordsLimit}&search=${search}`
    );
    setIsLoading(true);
    const response = await Get(url, props.token);
    if (response !== undefined) {
      console.log("response", response);
      setShowList(response?.data);
      setTotalPages(response?.data?.totalResults);
    }
    setIsLoading(false);
  };

  useEffect(() => {
    getAllListing();
  }, []);

  useEffect(() => {
    getAllListing();
  }, [props.searchSetDataFlag, props.setSearchSaveTabStatus]);

  const handleContextMenu = (event) => {
    event.preventDefault();
    setContextMenuItems([
      {
        label: "Edit SearchSet",
        onClick: () => {
          if (!props.setEditSearchTabStatus) {
            props.setEditSearchDataAction(structuredClone(selectedList));
            props.setEditSearchTabStatusAction(true);
            // props.setPropertyTabStatusAction();
            props.setSearchTabStatusAction(false);
          }
        },
      },
      { label: "Close", onClick: () => closeContextMenu() },
    ]);
    console.log("x", event.clientX);
    console.log("y", event.clientY);
    setContextMenuPosition({ x: event.clientX, y: event.clientY });
    setContextMenuVisible(true);
  };

  const closeContextMenu = () => {
    setContextMenuVisible(false);
  };

  const handleSelectList = (i) => {
    // console.log("HERE");
    setSelectedList(structuredClone(showList[i]));
  };
  // useEffect(() => {
  //   getAllListing();
  //   console.log("show", showList);
  // }, [props.searchSaveListFlag]);
  const closeTab = () => {
    props.setSearchSaveTabStatusAction(false);
  };

  const handleDoubleClick = (data) => {
    const name = data.modelProperty;
    const value = data.modelPropertyValue;
    const color = null;
    const transparency = null;
    props.findModel({ name, value, color, transparency });
  };

  return (
    <div className="search-sets-tab" onContextMenu={handleContextMenu}>
      <Tab
        isOpen={props.setSearchSaveTabStatus}
        onClose={closeTab}
        tabName={"SearchSets Tab"}
      >
        <ul className="search-sets-tab-ul">
          {!isLoading
            ? showList.map((_, i) => (
                <li
                  onMouseUp={() => handleSelectList(i)}
                  onDoubleClick={() => handleDoubleClick(_)}
                  className="search-sets-tab-li-item"
                  key={i}
                  value={_.name}
                >
                  <div>
                    <img src={PNG_BINOCULAR} alt="Binocular" />
                  </div>
                  <div>{_.name}</div>
                </li>
              ))
            : null}
        </ul>
      </Tab>
      <div>
        {contextMenuVisible && (
          <ContextMenu
            items={contextMenuItems}
            position={contextMenuPosition}
            onClose={closeContextMenu}
          />
        )}
      </div>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    setEditSearchTabStatus: state.EditSearchReducer.setEditSearchTabStatus,
    searchSaveList: state.SearchSetsReducer.searchSaveList,
    searchSaveListFlag: state.SearchSetsReducer.searchSaveListFlag,
    setSearchSaveTabStatus: state.SearchSetsReducer.setSearchSaveTabStatus,
    searchSetDataFlag: state.SearchSetsReducer.searchSetDataFlag,
    projectId: state.ProjectReducer.projectId,
    token: state.AuthReducer.token,
  };
};

const mapDispatchToProps = {
  setEditSearchTabStatusAction,
  setPropertyTabStatusAction,
  setSearchSaveTabStatusAction,
  setSearchTabStatusAction,
  setEditSearchDataAction,
  findModel,
};

export default connect(mapStateToProps, mapDispatchToProps)(SearchSetsTab);
