export const Constants = {
  GET_VIEWER_INPUT_ONCLICK_INSTANT: "GET_VIEWER_INPUT_ONCLICK_INSTANT",

  GET_MEASUREMENT_MOUSE_CONTROL_INSTANT:
    "GET_MEASUREMENT_MOUSE_CONTROL_INSTANT",

  GET_MODEL_PROPERTIES: "GET_MODEL_PROPERTIES",

  SAVE_VIEWER: "SAVE_VIEWER",
  SET_PROPERTY_TAB_STATUS: "SET_PROPERTY_TAB_STATUS",

  SET_SECTIONCUT_BTN_STATUS: "SET_SECTIONCUT_BTN_STATUS",
  SET_MEASUREMENT_BTN_STATUS: "SET_MEASUREMENT_BTN_STATUS",
  SET_SELECTENTITY_STATUS: "SET_SELECTENTITY_BTN_STATUS",

  SET_SEARCHTAB_STATUS: "SET_SEARCHTAB_STATUS",
  SET_SEARCHSAVETAB_STATUS: "SET_SEARCHSAVETAB_STATUS",
  SET_CLASHDETECTIONTAB_STATUS: "SET_CLASHDETECTIONTAB_STATUS",
  SET_APPEARENCEPROFILETAB_STATUS: "SET_APPEARENCEPROFILETAB_STATUS",
  SAVE_APPEARENCEPROFILE_LIST: "SAVE_APPEARENCEPROFILE_LIST",

  FIND_MODEL: "FIND_MODEL",
  SAVE_SEARCH_LIST: "SAVE_SEARCH_LIST",

  DETECT_COLLISION: "DETECT_COLLISION",
  SAVE_CLASH_DETECTION_LIST: "SAVE_CLASH_DETECTION_LIST",

  SET_ANNOTATIONTAB_STATUS: "SET_ANNOTATIONTAB_STATUS",
  GET_ANNOTATIONTAB_MOUSE_INPUT: "GET_ANNOTATIONTAB_MOUSE_INPUT",

  SET_APPEARENCETEMPLATETAB_STATUS: "SET_APPEARENCETEMPLATETAB_STATUS",
  SAVE_APPEARENCETEMPLATE_LIST: "SAVE_APPEARENCETEMPLATE_LIST",

  SEARCH_APPEARENCETEMPLATE_LIST: "SEARCH_APPEARENCETEMPLATE_LIST",

  SAVE_CLASHDETECTION_ARRAY: "SAVE_CLASHDETECTION_ARRAY",

  ADD_TO_CLASHDETECTION_LIST_FROM_VIEW: "ADD_TO_CLASHDETECTION_LIST_FROM_VIEW",
  ADD_TO_CLASHDETECTION_LIST_NORMAL_CLICK:
    "ADD_TO_CLASHDETECTION_LIST_NORMAL_CLICK",
  SELECT_CONTEXT_MENU_BTN_STATUS: "SELECT_CONTEXT_MENU_BTN_STATUS",

  SET_DISTANCEMEASUREMENTTAB_STATUS: "SET_DISTANCEMEASUREMENTTAB_STATUS",
  SAVE_DISTANCEMEASUREMENT_PLUGIN: "SAVE_DISTANCEMEASUREMENT_PLUGIN",
  SAVE_DISTANCEMEASUREMENT_ARRAY: "SAVE_DISTANCEMEASUREMENT_ARRAY",

  SAVE_SECTIONPLANE_PLUGIN: "SAVE_SECTIONPLANE_PLUGIN",
  SET_RESET_SECTIONPLANE_BTN_STATUS: "SET_RESET_SECTIONPLANE_BTN_STATUS",

  SAVE_CURRENT_VIEWER: "SAVE_CURRENT_VIEWER",

  SET_THUMBNAIL_OPACITY: "SET_THUMBNAIL_OPACITY",
  SELECT_APPREARENCE_TEMPLATE_VALUE: "SELECT_APPREARENCE_TEMPLATE_VALUE",

  SAVE_ACTION_BUFFER: "SAVE_ACTION_BUFFER",
  SAVE_SCREENSHOT_DATA: "SAVE_SCREENSHOT_DATA",

  SET_ISSUE_TRACK_STATUS: "SET_ISSUE_TRACK_STATUS",
  SAVE_ISSUE_ARRAY: "SAVE_ISSUE_ARRAY",
  SAVE_LOG_ARRAY: "SAVE_LOG_ARRAY",

  SET_UPDATE_SCREEN_ISSUE_ID: "SET_UPDATE_SCREEN_ISSUE_ID",

  SET_ANNOTATION_OPTION_TAB: "SET_ANNOTATION_OPTION_TAB",

  SAVE_METADATA: "SAVE_METADATA",
  SET_MULTI_SELECT_ENTITY: "SET_MULTI_SELECT_ENTITY",

  SET_CLASH_TEST_TAB_STATUS: "SET_CLASH_TEST_TAB_STATUS",

  SET_EDIT_SEARCH_TAB_STATUS: "SET_EDIT_SEARCH_TAB_STATUS",
  SET_EDIT_SEARCH_DATA: "SET_EDIT_SEARCH_DATA",

  SET_SELECT_PROPERTY_DATA: "SET_SELECT_PROPERTY_DATA",
  SET_COMMON_PROPERTY_DATA: "SET_COMMON_PROPERTY_DATA",

  SET_TREE_VIEW_STATUS: "SET_TREE_VIEW_STATUS",

  SET_SEARCHSET_DATA_FLAG: "SET_SEARCHSET_DATA_FLAG",

  SET_IMAGEPLANE_TAB_STATUS: "SET_IMAGEPLANE_TAB_STATUS",

  SAVE_BCFVIEWPOINT: "SAVE_BCFVIEWPOINT",
  SAVE_ANNOTATIONPLUGIN: "SAVE_ANNOTATIONPLUGIN",
  SAVE_MEASUREMENTPLUGIN: "SAVE_MEASUREMENTPLUGIN",
  SAVE_SECTIONPLANEPLUGIN: "SAVE_SECTIONPLANEPLUGIN",

  SAVE_SECTIONPLANECANVAS: "SAVE_SECTIONPLANECANVAS",

  USER_LOGIN: "USER_LOGIN",
  USER_LOGOUT: "USER_LOGOUT",

  SAVE_PROJECT: "SAVE_PROJECT",

  TOKEN:
    "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJzdWIiOiI2NzA2OGJjMDFhNTg5ZDczNDQ2NjU5YTQiLCJpYXQiOjE3MzI5Mzc3MTQsImV4cCI6MTc1MDkzNzcxNCwidHlwZSI6ImFjY2VzcyJ9.YJMNDEMz2doEHdro5Y75Heot-jxw94jB3afWhVJxqLc",

  // TOKEN:
  //   "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJzdWIiOiI2NmFiZmUzZmE4NjNjODdmMzIzZTBkMTIiLCJpYXQiOjE3MzE5NjA2NDIsImV4cCI6MTczMzc2MDY0MiwidHlwZSI6ImFjY2VzcyJ9.JlQ4A-dQU-R_zRx6n3qSdnYcCXA_WZfBUjHCKgNx4rE",
};
