import React, { useEffect, useState } from "react";
import { connect } from "react-redux";

import Modal from "../../components/Modal/Modal";
import TabPanel from "./TabPanel";

import "./IssueTrack.css";

import {
  setIssueTrackStatusAction,
  saveIssueArrayAction,
  saveLogArrayAction,
} from "../../action/xeokitAction";

const IssueTrack = ({
  isIssueTrackOpen,
  imageData,
  IssuesArray,
  logsArray,
  updateScreenIssueId,
  setIssueTrackStatusAction,
  saveIssueArrayAction,
  saveLogArrayAction,
}) => {
  const [detailTabData, setDetailTabData] = useState({});
  const [logTabData, setLogTabData] = useState([]);

  useEffect(() => {
    setLogTabData(logsArray[updateScreenIssueId] || []);
  }, [logsArray]);

  const closeModal = () => setIssueTrackStatusAction(false);

  const handleRowClick = (rowData, index) => {
    setDetailTabData(rowData);
    setLogTabData(structuredClone(logsArray[index]));
  };

  const saveUpdatedIssue = (objectId, objectKey, objectValue) => {
    const updatedIssues = structuredClone(IssuesArray);
    const issueIndex = updatedIssues.findIndex((item) => item.id === objectId);

    if (issueIndex !== -1) {
      updatedIssues[issueIndex][objectKey] = objectValue;
      setDetailTabData(updatedIssues[issueIndex]);

      const updatedLogs = structuredClone(logsArray);
      updatedLogs[issueIndex].push({
        [objectKey]: objectValue,
        createdDate: formatDate(),
      });

      setLogTabData(updatedLogs[issueIndex]);
      saveLogArrayAction(updatedLogs);
      saveIssueArrayAction(updatedIssues);
    }
  };

  const formatDate = () => {
    const now = new Date();

    // Get the components of the date
    const day = String(now.getDate()).padStart(2, "0");
    const month = String(now.getMonth() + 1).padStart(2, "0"); // Months are zero-based
    const year = now.getFullYear();
    const hours = now.getHours();
    const minutes = String(now.getMinutes()).padStart(2, "0");

    // Determine AM or PM
    const ampm = hours >= 12 ? "pm" : "am";

    // Format the hours in 12-hour format
    const formattedHours = hours % 12 || 12;

    // Format the date and time as desired
    const formattedDate = `${day}/${month}/${year} at ${formattedHours}:${minutes} ${ampm}`;

    return formattedDate;
  };

  return (
    <Modal isOpen={isIssueTrackOpen}>
      <div className="issue-track-model">
        <div className="issue-track-modal-header">
          <label className="issue-track-modal-title">Issue Tracker</label>
          <span className="issue-track-modal-close" onClick={closeModal}>
            X
          </span>
        </div>
        <div className="issue-track-modal-body">
          <div className="issue-track-list">
            <table className="table">
              <thead>
                <tr>
                  <th>
                    <input type="checkbox" />
                  </th>
                  <th>ID</th>
                  <th>Title</th>
                  <th>Status</th>
                  <th>Type</th>
                  <th>Assigned to</th>
                  <th>Due date</th>
                  <th>Start date</th>
                </tr>
              </thead>
              <tbody>
                {IssuesArray.map((issue, i) => (
                  <tr
                    className="issue-track-table-row"
                    key={issue.id}
                    onClick={() => handleRowClick(issue, i)}
                  >
                    <td>
                      <input type="checkbox" />
                    </td>
                    <td>{issue.id}</td>
                    <td>{issue.title}</td>
                    <td>{issue.status}</td>
                    <td>{issue.type}</td>
                    <td>{issue.assignee}</td>
                    <td>{issue.deadline}</td>
                    <td>{issue.created}</td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
          <div className="issue-track-tab">
            <TabPanel
              imageData={imageData}
              detailTabData={detailTabData}
              saveUpdatedIssue={saveUpdatedIssue}
              logTabData={logTabData}
            />
          </div>
        </div>
      </div>
    </Modal>
  );
};

const mapStateToProps = (state) => ({
  isIssueTrackOpen: state.IssueTrackReducer.isIssueTrackOpen,
  imageData: state.AnnotationReducer.imageData,
  IssuesArray: state.IssueTrackReducer.IssuesArray,
  logsArray: state.IssueTrackReducer.logsArray,
  updateScreenIssueId: state.IssueTrackReducer.updateScreenIssueId,
});

const mapDispatchToProps = {
  setIssueTrackStatusAction,
  saveIssueArrayAction,
  saveLogArrayAction,
};

export default connect(mapStateToProps, mapDispatchToProps)(IssueTrack);
