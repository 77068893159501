import React, { useState, useEffect } from "react";
import { toast } from "react-toastify";
import { connect } from "react-redux";

import { AnnotationsPlugin } from "https://cdn.jsdelivr.net/npm/@xeokit/xeokit-sdk/dist/xeokit-sdk.es.min.js";

import Tab from "../../components/Tab/Tab";
import Tab2 from "../../components/Tab/Tab2";

import "./AnnotationTab.css";

import {
  setAnnotationTabStatusAction,
  getAnnotationTabMouseInputAction,
  seSelectEntityStatusAction,
  setIssueTrackStatusAction,
  saveIssueArrayAction,
  saveLogArrayAction,
  setAnnotationOptionTabAction,
} from "../../action/xeokitAction";

import { apiHeader, BaseURL, recordsLimit } from "../../config/apiUrl";
import { Get, Patch } from "../../axios/axios";
import { Constants } from "../../constant/constants";

const AnnotationTab = (props) => {
  const defaultIssue = {
    id: 595,
    stamp: "1AR",
    title: "Arch_Coord - add decription here",
    type: "Standard issue",
    status: "open",
    assignee: "ben stokoe",
    priority: "none",
    deadline: "none",
    color: "red",
    created: "13/Aug/2024",
    reporter: "Nathan Lenarduzzi",
    wachers: [
      "Adrian Wilkins",
      "Alexander Salna",
      "Calhum Craig",
      "Nathan Lenarduzzi",
    ],
    tags: "coordination issue",
    defaultSheet: "---",
    room: "UE4B-LIVERBLE 2901",
    level: "LEVEL 29",
    area: "GFA RESIDENTIAL FAST",
  };

  const [issueType, setIssueType] = useState("");
  const [isIssueTypeClicked, setIsIssueTypeClicked] = useState(false);
  const [clickedIssueType, setClickedIssueType] = useState(null);

  const [isAnnotationOptionTabOpen, setIsAnnotationOptionTabOpen] =
    useState(false);

  const [annotationTypes, setAnnotationTypes] = useState([]);

  const [isLoading, setIsLoading] = useState(false);
  const [page, setPage] = useState(1);
  const [search, setSearch] = useState("");
  const [totalPages, setTotalPages] = useState();

  const getAllListing = async (pg = page) => {
    const url = BaseURL(
      `annotationTypes?page=${pg}&limit=${recordsLimit}&search=${search}`
    );
    setIsLoading(true);
    const response = await Get(url, props.token);
    if (response !== undefined) {
      console.log("response", response);
      setAnnotationTypes(response?.data?.results);
      setTotalPages(response?.data?.totalResults);
    }
    setIsLoading(false);
  };

  useEffect(() => {
    getAllListing();
  }, [props.isAnnotationTabOpen]);

  const handleAnnotationTab = () => {
    props.seSelectEntityStatusAction(true);
    props.setAnnotationTabStatusAction(false);
    setIsIssueTypeClicked(false);
    setIssueType(null);
  };

  const handleAnnotationOptionTab = () => {
    // setIsAnnotationOptionTabOpen(false);
    setAnnotationOptionTabAction(false);
  };
  const handleIssueTypeSelection = (data) => {
    setClickedIssueType(data.abbreviation);
    setIssueType(data);
    setIsIssueTypeClicked(true);
  };

  const captureScreenshot = async () => {
    // const img = new Image();
    // const retVal = await props.viewer.getSnapshotWithPlugins({
    //   format: "png",
    //   width: img.width * 3, // Upscale snapshot resolution 2x
    //   height: img.height * 3,
    // });
    // return retVal;
    console.log("captureViewer", props.viewer);
    const screenshot = props.viewer.getSnapshotWithPlugins({
      format: "png",
      width: window.innerWidth * 3, // Upscale snapshot resolution by 3x
      height: window.innerHeight * 3,
    });

    return screenshot;
  };

  const updateScreenshotForIssue = async () => {
    const updateScreenshot = async () => {
      const newScreenshot = await captureScreenshot();
      const updatedIssues = props.issues;
      updatedIssues[props.issueToUpdateId].markUp = newScreenshot;
      props.saveIssues(updatedIssues);
    };
  };

  useEffect(() => {
    const { viewer, setAnnotationOptionTabAction } = props;
    if (!viewer || !isIssueTypeClicked) return;

    const annotations = new AnnotationsPlugin(viewer, {
      container: document.getElementById("annotationsContainer"),
      markerHTML:
        "<div class='annotation-marker' style='background-color: {{markerBGColor}}; color: {{fontColor}}; borderColor:{{borderColor}}; '>{{glyph}}</div>",
      values: {
        markerBGColor: "white",
        labelBGColor: "red",
        glyph: "X",
        fontColor: "red",
        borderColor: "red",
      },
    });

    annotations.on("markerClicked", (annotation) => {
      annotation.labelShown = !annotation.labelShown;
    });

    let annotationCount = 1;

    const handleMouseClick = viewer.scene.input.on(
      "mouseclicked",
      async (coords) => {
        const pickResult = viewer.scene.pick({
          canvasPos: coords,
          pickSurface: true,
        });

        if (pickResult) {
          console.log("IssueType", issueType);
          const test = annotations.createAnnotation({
            id: `annotation${annotationCount}`,
            pickResult: pickResult,
            occludable: true,
            markerShown: true,
            labelShown: true,
            values: {
              glyph: issueType.abbreviation,
              fontColor: issueType.color,
              borderColor: issueType.color,
            },
          });
          console.log("Test", test);
          console.log("Test", props.viewer);
          annotationCount++;
          // setTimeout(handleIssueTracking, 1000);
          // handleIssueTracking();
          if (annotationCount === 2) {
            viewer.scene.input.off(handleMouseClick);
            // setAnnotationOptionTabAction(true);
            // handleIssueTracking();
          }
        }
      }
    );
  }, [issueType]);

  const handleIssueTracking = async () => {
    const screenshot = await captureScreenshot();

    console.log("screenshot", screenshot);

    props.setIssueTrackStatusAction(true);

    const issues = structuredClone(props.IssuesArray);
    const newIssue = {
      ...defaultIssue,
      id: issues.length + 1,
      markUp: screenshot,
    };
    issues.push(newIssue);
    props.saveIssueArrayAction(structuredClone(issues));

    const logs = structuredClone(props.logsArray);
    logs.push([{ markUp: screenshot }]);
    props.saveLogArrayAction(structuredClone(logs));
  };
  return (
    <>
      <div id="annotationsContainer"></div>
      <div className="annotation-tab">
        <Tab
          isOpen={props.isAnnotationTabOpen}
          onClose={handleAnnotationTab}
          tabName={"Annotation Tab"}
        >
          <div className="annotation-tab-body">
            {annotationTypes.map((annotationType, index) => (
              <div
                onClick={() => handleIssueTypeSelection(annotationType)}
                className={
                  clickedIssueType === annotationType.abbreviation
                    ? "issue-type-clicked"
                    : "issue-type"
                }
                style={{
                  color: annotationType.color,
                  borderColor: annotationType.color,
                }}
              >
                {annotationType.abbreviation}
              </div>
            ))}
          </div>
        </Tab>
      </div>
      {/* <div className="annotation-option-tab">
        <Tab2
          isOpen={props.setAnnotationOptionTabStatus}
          onClose={handleAnnotationOptionTab}
          tabName={"AnnotationOption Tab"}
        >
          <button
            className="custom-button"
            onClick={() => updateScreenshotForIssue()}
          >
            scrrenshot
          </button>
          <button
            className="custom-button"
            onClick={() => handleIssueTracking()}
          >
            Issue Track
          </button>
        </Tab2>
      </div> */}
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    viewer: state.xeokitReducer.viewer,
    isAnnotationTabOpen: state.AnnotationReducer.isAnnotationTabOpen,
    annotationTabMouseInput: state.AnnotationReducer.annotationTabMouseInput,
    IssuesArray: state.IssueTrackReducer.IssuesArray,
    logsArray: state.IssueTrackReducer.logsArray,
    updateScreenIssueId: state.IssueTrackReducer.updateScreenIssueId,
    setAnnotationOptionTabStatus:
      state.IssueTrackReducer.setAnnotationOptionTabStatus,
    token: state.AuthReducer.token,
  };
};

const mapDispatchToProps = {
  setAnnotationTabStatusAction,
  getAnnotationTabMouseInputAction,
  seSelectEntityStatusAction,
  setIssueTrackStatusAction,
  saveIssueArrayAction,
  saveLogArrayAction,
  setAnnotationOptionTabAction,
};

export default connect(mapStateToProps, mapDispatchToProps)(AnnotationTab);
