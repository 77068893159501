import React from "react";
import "./Dropdown.css";

const Dropdown = React.memo(({ optionArray, onChangeOption, defaultValue }) => {
  console.log("11", defaultValue);
  return (
    <>
      <select
        className="select"
        onChange={(e) => onChangeOption(e)}
        value={defaultValue}
      >
        {optionArray?.map((element, i) => (
          <option value={element} key={i}>
            {element}
          </option>
        ))}
      </select>
    </>
  );
});

export default Dropdown;
