import React, { useRef, useState, useEffect } from "react";
import { connect } from "react-redux";
import "./CustomCanvas.css";

import Import_SVG from "../../asset/import.svg";
import Export_SVG from "../../asset/export.svg";
import Undo_SVG from "../../asset/undo.svg";
import Redo_SVG from "../../asset/redo.svg";
import Move_SVG from "../../asset/move.svg";
import Pen_SVG from "../../asset/pen.svg";
import Rect_SVG from "../../asset/rectangle.svg";
import Circle_SVG from "../../asset/circle.svg";

import {
  saveImageDataAction,
  saveLogArrayAction,
} from "../../action/xeokitAction";

const Canvas = ({
  backgroundImage,
  saveImageDataAction,
  saveLogArrayAction,
  logsArray,
  updateScreenIssueId,
}) => {
  const canvasRef = useRef(null);
  const [isDrawing, setIsDrawing] = useState(false);
  const [tool, setTool] = useState("pen");
  const [startPoint, setStartPoint] = useState(null);
  const [lineWidth, setLineWidth] = useState(5);
  const [color, setColor] = useState("black");
  const drawingContextRef = useRef(null);
  const historyContextRef = useRef(null);
  const backgroundRef = useRef(null);

  // History state for undo/redo
  const [history, setHistory] = useState([]);
  const [historyIndex, setHistoryIndex] = useState(-1);

  useEffect(() => {
    const canvas = canvasRef.current;
    const context = canvas.getContext("2d");
    initializeCanvas(canvas, context);
    loadBackgroundImage(backgroundImage, context);
  }, [backgroundImage]);

  const initializeCanvas = (canvas, context) => {
    canvas.width = window.innerWidth * 2;
    canvas.height = window.innerHeight * 2;
    canvas.style.width = `${window.innerWidth}px`;
    canvas.style.height = `${window.innerHeight}px`;
    context.scale(2, 2);
    context.lineCap = "round";

    drawingContextRef.current = createOffscreenCanvas(canvas);
    historyContextRef.current = createOffscreenCanvas(canvas);
  };

  const createOffscreenCanvas = (canvas) => {
    const offscreenCanvas = document.createElement("canvas");
    offscreenCanvas.width = canvas.width;
    offscreenCanvas.height = canvas.height;
    return offscreenCanvas.getContext("2d");
  };

  const loadBackgroundImage = (src, context) => {
    const image = new Image();
    image.src = src;
    image.onload = () => {
      backgroundRef.current = image;
      redrawMainCanvas(); // Call redraw to load the background and existing drawings
    };
  };

  const startDrawing = ({ nativeEvent: { offsetX, offsetY } }) => {
    setStartPoint({ x: offsetX, y: offsetY });
    setIsDrawing(true);
    const drawCtx = drawingContextRef.current;
    drawCtx.strokeStyle = color;
    drawCtx.lineWidth = lineWidth;

    if (tool === "pen") {
      drawCtx.beginPath();
      drawCtx.moveTo(offsetX, offsetY);
    }
  };

  const finishDrawing = () => {
    if (!isDrawing) return;
    setIsDrawing(false);

    const drawCtx = drawingContextRef.current;
    const historyCtx = historyContextRef.current;

    // Preserve the current drawing to the history context
    historyCtx.drawImage(drawCtx.canvas, 0, 0);
    drawCtx.clearRect(0, 0, drawCtx.canvas.width, drawCtx.canvas.height);

    // Save the current canvas state for undo/redo
    const newHistory = history.slice(0, historyIndex + 1);
    newHistory.push(
      historyCtx.getImageData(
        0,
        0,
        historyCtx.canvas.width,
        historyCtx.canvas.height
      )
    );
    setHistory(newHistory);
    setHistoryIndex(newHistory.length - 1);

    redrawMainCanvas();
  };

  const draw = ({ nativeEvent: { offsetX, offsetY } }) => {
    if (!isDrawing) return;
    const drawCtx = drawingContextRef.current;

    if (tool === "pen") {
      drawCtx.lineTo(offsetX, offsetY);
      drawCtx.stroke();
    } else {
      drawCtx.clearRect(0, 0, drawCtx.canvas.width, drawCtx.canvas.height);
      renderFinalShape(drawCtx, tool, startPoint, { x: offsetX, y: offsetY });
    }

    redrawMainCanvas();
  };

  const renderFinalShape = (ctx, tool, start, end) => {
    const { x, y } = start;
    const width = end.x - x;
    const height = end.y - y;

    switch (tool) {
      case "rectangle":
        ctx.strokeRect(x, y, width, height);
        break;
      case "arrow":
        drawArrow(ctx, x, y, end.x, end.y);
        break;
      case "line":
        drawLine(ctx, x, y, end.x, end.y);
        break;
      case "circle":
        const radius = Math.sqrt(width * width + height * height);
        drawCircle(ctx, x + width / 2, y + height / 2, radius);
        break;
      default:
        break;
    }
  };

  const redrawMainCanvas = () => {
    const context = canvasRef.current.getContext("2d");
    context.clearRect(0, 0, canvasRef.current.width, canvasRef.current.height);
    if (backgroundRef.current) {
      context.drawImage(
        backgroundRef.current,
        0,
        0,
        canvasRef.current.width / 2,
        canvasRef.current.height / 2
      );
    }
    context.drawImage(historyContextRef.current.canvas, 0, 0);
    context.drawImage(drawingContextRef.current.canvas, 0, 0);
  };

  const drawArrow = (ctx, fromX, fromY, toX, toY) => {
    const headLength = 15;
    const angle = Math.atan2(toY - fromY, toX - fromX);

    ctx.beginPath();
    ctx.moveTo(fromX, fromY);
    ctx.lineTo(toX, toY);
    ctx.lineTo(
      toX - headLength * Math.cos(angle - Math.PI / 6),
      toY - headLength * Math.sin(angle - Math.PI / 6)
    );
    ctx.moveTo(toX, toY);
    ctx.lineTo(
      toX - headLength * Math.cos(angle + Math.PI / 6),
      toY - headLength * Math.sin(angle + Math.PI / 6)
    );
    ctx.stroke();
  };

  const drawLine = (ctx, fromX, fromY, toX, toY) => {
    ctx.beginPath();
    ctx.moveTo(fromX, fromY);
    ctx.lineTo(toX, toY);
    ctx.stroke();
  };

  const drawCircle = (ctx, x, y, radius) => {
    ctx.beginPath();
    ctx.arc(x, y, radius, 0, 2 * Math.PI);
    ctx.stroke();
  };

  const undo = () => {
    if (historyIndex <= 0) return;
    setHistoryIndex(historyIndex - 1);
    const historyCtx = historyContextRef.current;
    historyCtx.putImageData(history[historyIndex - 1], 0, 0);
    redrawMainCanvas();
  };

  const redo = () => {
    if (historyIndex >= history.length - 1) return;
    setHistoryIndex(historyIndex + 1);
    const historyCtx = historyContextRef.current;
    historyCtx.putImageData(history[historyIndex + 1], 0, 0);
    redrawMainCanvas();
  };

  const getImageData = () => {
    console.log("logsArray", logsArray);
    const canvas = canvasRef.current;
    // saveImageDataAction(canvas.toDataURL("image/png"));

    const tempLogsArray = structuredClone(logsArray);

    tempLogsArray[updateScreenIssueId].push({
      markUp: canvas.toDataURL("image/png"),
    });
    saveLogArrayAction(structuredClone(tempLogsArray));
  };

  return (
    <div>
      <canvas
        ref={canvasRef}
        onMouseDown={startDrawing}
        onMouseUp={finishDrawing}
        onMouseMove={draw}
        style={{
          border: "1px solid black",
          cursor: "crosshair",
          backgroundColor: "lightblue",
        }}
      />
      <div className="image-editor-menu">
        <button className="button color" onClick={() => setTool("pen")}>
          <img src={Pen_SVG} alt="redo" title="red" />
          Pen
        </button>
        <button className="button color" onClick={() => setTool("rectangle")}>
          <img src={Rect_SVG} alt="redo" title="red" />
          Rectangle
        </button>
        <button className="button color" onClick={() => setTool("arrow")}>
          Arrow
        </button>
        <button className="button color" onClick={() => setTool("line")}>
          Line
        </button>
        <button className="button color" onClick={() => setTool("circle")}>
          <img src={Circle_SVG} alt="redo" title="red" />
          Circle
        </button>
        <input
          type="color"
          value={color}
          onChange={(e) => setColor(e.target.value)}
        />
        <select
          value={lineWidth}
          onChange={(e) => setLineWidth(parseInt(e.target.value, 10))}
        >
          <option value={1}>1</option>
          <option value={3}>3</option>
          <option value={5}>5</option>
          <option value={7}>7</option>
          <option value={10}>10</option>
        </select>
        <button className="button color" onClick={undo}>
          <img src={Undo_SVG} alt="redo" title="red" />
          Undo
        </button>
        <button className="button color" onClick={redo}>
          <img src={Redo_SVG} alt="redo" title="red" />
          Redo
        </button>
        <button
          className="button color"
          onClick={() => console.log(getImageData())}
        >
          Get Image Data
        </button>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    logsArray: state.IssueTrackReducer.logsArray,
    updateScreenIssueId: state.IssueTrackReducer.updateScreenIssueId,
  };
};

const mapDispatchToProps = {
  saveImageDataAction,
  saveLogArrayAction,
};

export default connect(mapStateToProps, mapDispatchToProps)(Canvas);
