import { persistReducer, persistStore } from "redux-persist";
import storage from "redux-persist/lib/storage";
import { createStore, applyMiddleware } from "redux";
import { composeWithDevTools } from "@redux-devtools/extension";
import { thunk } from "redux-thunk";
import rootReducer from "./CombineReducer";

const persistConfig = {
  key: "root",
  storage,
  whitelist: ["AuthReducer", "ProjectReducer"],
  blacklist: [
    "xeokitReducer",
    "AnnotationReducer",
    "AppearenceProfileReducer",
    "AppearenceTemplateReducer",
    "ClashDetectionReducer",
    "PropertyReducer",
    "SearchSetsReducer",
    "EditSearchReducer",
    "SearchReducer",
    "ActionBufferReducer",
    "IssueTrackReducer",
    "TreeViewReducer",
    "ImagePlaneReducer",
  ],
};

const persistedReducer = persistReducer(persistConfig, rootReducer);

const store = createStore(
  persistedReducer,
  composeWithDevTools(applyMiddleware(thunk))
);

export const persistor = persistStore(store);

export default store;
