import React from "react";

import "./Tab2.css";
const Tab = ({ isOpen, onClose, children, tabName }) => {
  if (!isOpen) return null;
  return (
    <div className="tab2">
      <div className="tab2-header">
        <label>{tabName}</label>
        <span className="tab2-header-cancel-btn" onClick={onClose}>
          X
        </span>
      </div>
      <div className="tab2-body">{children}</div>
    </div>
  );
};

export default Tab;
