import React, { useState, useEffect } from "react";
import { CustomPicker } from "react-color";
import {
  Saturation,
  EditableInput,
  Hue,
} from "react-color/lib/components/common";

import tinycolor from "tinycolor2";
import "./ColorPicker.css";

const onPointerMouseDown = (event) => {
  const pointer = document.querySelector(".custom-pointer");
  const pointerContainer = pointer?.parentElement;
  if (pointerContainer) {
    pointerContainer.style.top = event.clientY + "px";
    pointerContainer.style.left = event.clientX + "px";
  }
};

const CustomSlider = () => {
  return <div className="custom-slider" />;
};

const CustomPointer = () => {
  return <div className="custom-pointer" />;
};

const CustomColorPicker = ({ hexCode, onChange, colors }) => {
  const [redValue, setRedValue] = useState(0);
  const [greenValue, setGreenValue] = useState(0);
  const [blueValue, setBlueValue] = useState(0);
  //   const [hexColor, setHexColor] = useState("#000000");

  const handleSliderChange = (event, color) => {
    const value = event.target.value;
    if (color === "red") setRedValue(value);
    if (color === "green") setGreenValue(value);
    if (color === "blue") setBlueValue(value);
  };

  useEffect(() => {
    generateColor();
  }, [redValue, greenValue, blueValue]);
  const [hsl, setHsl] = useState({ h: 0, s: 0, l: 0 });
  const [hsv, setHsv] = useState({ h: 0, s: 0, v: 0 });
  const [hex, setHex] = useState("aaaaaa");
  const [rgb, setRgb] = useState({ r: 0, g: 0, b: 0 });

  const generateColor = () => {
    const finalColor = `rgb(${redValue}, ${greenValue}, ${blueValue})`;
    setRgb(finalColor);
    updateHexColor(finalColor);
    // setResultColor(finalColor);
    // updateHexColor(finalColor);
    //   document.body.style.backgroundColor = finalColor;
    console.log("final", finalColor);
  };

  const updateHexColor = (rgbColor) => {
    const hexColor = rgbToHex(rgbColor);
    setHex(hexColor);
    onChange(hexColor);
  };

  const rgbToHex = (rgb) => {
    const rgbValues = rgb.match(/\d+/g);
    const hexColor = `#${Number(rgbValues[0])
      .toString(16)
      .padStart(2, "0")}${Number(rgbValues[1])
      .toString(16)
      .padStart(2, "0")}${Number(rgbValues[2]).toString(16).padStart(2, "0")}`;
    return hexColor;
  };

  useEffect(() => {
    const color = tinycolor(hexCode);
    setHsl(color.toHsl());
    setHsv(color.toHsv());
    setHex(color.toHex());
  }, [hexCode]);

  const handleHueChange = (hue) => {
    setHsl(hue);
  };

  const handleSaturationChange = (hsv) => {
    const color = tinycolor(hsv);
    onChange(hsv);
    const input = document.body.getElementsByTagName("input");
    if (input[5]) {
      input[5].value = color.toHex();
    }
    HexToRgb(color.toHex());
    setHsl(color.toHsl());
  };

  const displayColorSwatches = (colors) => {
    return colors.map((color) => (
      <div
        onClick={() => onChange(color)}
        key={color}
        className="swatch-circle"
        style={{ backgroundColor: color }}
      />
    ));
  };

  const HexToRgb = (hexValue) => {
    if (/^#?[0-9A-Fa-f]{6}$/.test(hexValue)) {
      // Remove the hash at the start if it's there
      hexValue = hexValue.replace(/^#/, "");

      // Parse r, g, b values
      let r = parseInt(hexValue.slice(0, 2), 16);
      let g = parseInt(hexValue.slice(2, 4), 16);
      let b = parseInt(hexValue.slice(4, 6), 16);
      console.log("r", r);
      console.log("g", g);
      console.log("b", b);
      setRedValue(r);
      setGreenValue(g);
      setBlueValue(b);
    }
  };

  return (
    <div className="color-picker-container">
      <div className="saturation" onMouseDown={onPointerMouseDown}>
        <Saturation
          hsl={hsl}
          hsv={hsv}
          pointer={CustomPointer}
          onChange={handleSaturationChange}
        />
      </div>
      <div className="hue">
        <Hue
          hsl={hsl}
          pointer={CustomSlider}
          onChange={handleHueChange}
          direction={"horizontal"}
        />
      </div>
      <div className="hex-input">
        <span>Hex</span>
        <EditableInput
          style={{
            input: {
              border: "1px solid black",
              padding: "10px",
              fontSize: "15px",
              color: "#000",
            },
          }}
          value={hex}
          onChange={HexToRgb}
        />
      </div>
      <div className="wrapper">
        <label htmlFor="red">R</label>
        <input
          className="range-input red-gradient"
          type="range"
          min="0"
          max="255"
          value={redValue}
          id="red"
          onChange={(e) => handleSliderChange(e, "red")}
        />
      </div>
      <div className="wrapper">
        <label htmlFor="green">G</label>
        <input
          className="range-input green-gradient"
          type="range"
          min="0"
          max="255"
          value={greenValue}
          id="green"
          onChange={(e) => handleSliderChange(e, "green")}
        />
      </div>
      <div className="wrapper">
        <label htmlFor="blue">B</label>
        <input
          className="range-input blue-gradient"
          type="range"
          min="0"
          max="255"
          value={blueValue}
          id="blue"
          onChange={(e) => handleSliderChange(e, "blue")}
        />
      </div>
      {colors?.length && (
        <div className="color-swatches">{displayColorSwatches(colors)}</div>
      )}
    </div>
  );
};

export default CustomPicker(CustomColorPicker);
