import React, { useState, useRef, useEffect } from "react";
import { connect } from "react-redux";

import Modal from "../../components/Modal/Modal";
import Canvas from "../../components/Canvas/CustomCanvas";

import "./DetailTab.css";

import {
  setIssueTrackStatusAction,
  setUpdateScreenIssueIdAction,
} from "../../action/xeokitAction";

import EDIT_IMG from "../../asset/edit.png";

const assigneeArray = [
  { name: "Alexander Salna", company: "Northrop Consulting Engineers" },
  { name: "Adrian Wilkins", company: "Dina Group" },
  { name: "Alex Moulis", company: "" },
  { name: "Alexandra Grech", company: "Moir Landscrap Architecture" },
  { name: "Alice Edwards", company: "" },
  { name: "Andrew Parker", company: "" },
];

const DetailTab = ({
  detailTabData,
  saveUpdatedIssue,
  setIssueTrackStatusAction,
  setUpdateScreenIssueIdAction,
}) => {
  const [tempDetailTabData, setTempDetailTabData] = useState({});
  const [commandData, setCommandData] = useState("");
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isImageEditorOpen, setIsImageEditorOpen] = useState(false);
  const [activeEdit, setActiveEdit] = useState(null);

  const editOptionRefs = useRef({
    stamp: null,
    title: null,
    status: null,
    assignee: null,
  });

  useEffect(() => {
    console.log("detailTabData", detailTabData);
    setTempDetailTabData({ ...detailTabData });
  }, [detailTabData]);

  useEffect(() => {
    const handleClickOutside = (event) => {
      Object.keys(editOptionRefs.current).forEach((key) => {
        if (
          activeEdit === key &&
          editOptionRefs.current[key] &&
          !editOptionRefs.current[key].contains(event.target)
        ) {
          setActiveEdit(null);
        }
      });
    };
    if (activeEdit) {
      document.addEventListener("mousedown", handleClickOutside);
    } else {
      document.removeEventListener("mousedown", handleClickOutside);
    }
    return () => document.removeEventListener("mousedown", handleClickOutside);
  }, [activeEdit]);

  const toggleModal = () => setIsModalOpen((prev) => !prev);

  const saveIssue = (key, value) => {
    saveUpdatedIssue(tempDetailTabData.id, key, value);
    setTempDetailTabData((prev) => ({ ...prev, [key]: value }));
    setActiveEdit(null);
  };

  const handleCommandChange = (e) => setCommandData(e.target.value);

  const handleCommandSave = () => saveIssue("comment", commandData);

  const handleEdit = (key) => setActiveEdit(key);

  const renderEditOptions = (key, options) => (
    <div
      className={`edit-option ${
        activeEdit === key ? "edit-option-show" : "edit-option-hide"
      }`}
      ref={(el) => (editOptionRefs.current[key] = el)}
    >
      {options}
    </div>
  );

  const onClickUpdateScreenBtn = () => {
    setIssueTrackStatusAction(false);
    toggleModal();
    setUpdateScreenIssueIdAction(tempDetailTabData.id - 1);
  };

  const onClickMarkUpBtn = () => {
    toggleModal();
    setIsImageEditorOpen(true);
    setUpdateScreenIssueIdAction(tempDetailTabData.id - 1);
  };
  return (
    <div className="detail-tab">
      {tempDetailTabData && (
        <>
          {/* Markup Section */}
          <div className="detail-tab-row-markup">
            <div className="detail-tab-row-title">Issue Thumbnail</div>
            <div className="detail-tab-row-content">
              <img
                src={tempDetailTabData.markUp}
                alt="markup"
                width="100%"
                height={250}
                className="detail-tab-row-img"
                onClick={toggleModal}
              />
            </div>
          </div>

          {/* Title Section */}
          <DetailRow
            label="Title"
            content={tempDetailTabData.title}
            onEdit={() => handleEdit("title")}
            editOptions={renderEditOptions(
              "title",
              <>
                <input
                  className="title-input"
                  value={tempDetailTabData.title || ""}
                  onChange={(e) => saveIssue("title", e.target.value)}
                />
                <ActionButtons
                  onApply={() => saveIssue("title", tempDetailTabData.title)}
                  onCancel={() => setActiveEdit(null)}
                />
              </>
            )}
          />

          {/* Status Section */}
          <DetailRow
            label="Status"
            content={tempDetailTabData.status}
            onEdit={() => handleEdit("status")}
            editOptions={renderEditOptions(
              "status",
              ["open", "in progress", "solved", "closed"].map((status) => (
                <Badge
                  key={status}
                  status={status}
                  onClick={() => saveIssue("status", status)}
                />
              ))
            )}
          />

          {/* Description Section */}
          <DetailRow
            label="Description"
            content={tempDetailTabData.description}
            onEdit={() => handleEdit("description")}
          />

          {/* Assignee Section */}
          <DetailRow
            label="Assigned to"
            content={tempDetailTabData.assignee}
            onEdit={() => handleEdit("assignee")}
            editOptions={renderEditOptions(
              "assignee",
              assigneeArray.map(({ name, company }) => (
                <div
                  key={name}
                  className="assignee-option"
                  onClick={() => saveIssue("assignee", name)}
                >
                  <div className="assignee-option-name">{name}</div>
                  <div className="assignee-option-company">{company}</div>
                </div>
              ))
            )}
          />

          {/* Comments Section */}
          <div className="detail-tab-row">
            <div className="detail-tab-row-title">
              <div>Comments</div>
            </div>
            <div className="detail-tab-row-comment-content">
              <textarea
                className="detail-tab-row-comment-text"
                value={commandData}
                onChange={handleCommandChange}
              ></textarea>
              <div>
                <button className="custom-button" onClick={handleCommandSave}>
                  Leave Comment
                </button>
              </div>
            </div>
          </div>
        </>
      )}

      {/* Modal */}
      <Modal isOpen={isModalOpen} onRequestClose={toggleModal}>
        <div className="issue-tracker-detail-modal">
          <div className="issue-tracker-detail-modal-header">
            <label>Mark Up</label>
            <button
              className="custom-button"
              onClick={() => onClickUpdateScreenBtn()}
            >
              Update ScreenShot
            </button>
            <button
              className="custom-button"
              onClick={() => onClickMarkUpBtn()}
            >
              Mark Up
            </button>
            <span className="modal-close" onClick={toggleModal}>
              X
            </span>
          </div>
          <div className="issue-tracker-detail-modal-body">
            <img
              src={tempDetailTabData.markUp}
              alt="Mark Up"
              width={900}
              height="100%"
            ></img>
          </div>
        </div>
        {/* <div className="issue-tracker-detail-modal">
          <div className="issue-tracker-detail-modal-header">
            <label>Mark Up</label>
            <ActionButtons
              onApply={() => saveIssue("markUp", tempDetailTabData.markUp)}
              onCancel={toggleModal}
            />
          </div>
          <img
            src={tempDetailTabData.markUp}
            alt="Mark Up"
            className="modal-image"
          />
        </div> */}
      </Modal>

      {/* Image Editor */}
      {isImageEditorOpen && (
        <div className="image-editor">
          <Canvas backgroundImage={tempDetailTabData.markUp} />
          <span
            className="image-editor-cancel-btn"
            onClick={() => setIsImageEditorOpen(false)}
          >
            X
          </span>
        </div>
      )}
    </div>
  );
};

const DetailRow = ({ label, content, onEdit, editOptions }) => (
  <div className="detail-tab-row">
    <div className="detail-tab-row-title">{label}</div>
    <div className="detail-tab-row-content">
      <div>{content}</div>
      {onEdit && (
        <img src={EDIT_IMG} className="edit-icon" alt="Edit" onClick={onEdit} />
      )}
    </div>
    {editOptions}
  </div>
);

const Badge = ({ status, onClick }) => (
  <div className={`annotation-badge ${status}`} onClick={onClick}>
    {status}
  </div>
);

const ActionButtons = ({ onApply, onCancel }) => (
  <div className="action-buttons">
    <button onClick={onApply}>Apply</button>
    <button onClick={onCancel}>Cancel</button>
  </div>
);

const mapStateToProps = (state) => {};

const mapDispatchToProps = {
  setIssueTrackStatusAction,
  setUpdateScreenIssueIdAction,
};

export default connect(null, mapDispatchToProps)(DetailTab);
