import { Constants } from "../constant/constants";

const initialState = {
  isAnnotationTabOpen: false,
  annotationTabMouseInput: [],
  imageData: [],
};
export function AnnotationReducer(state = initialState, action) {
  switch (action.type) {
    case Constants.SET_ANNOTATIONTAB_STATUS: {
      return { ...state, isAnnotationTabOpen: action.payload };
    }
    case Constants.GET_ANNOTATIONTAB_MOUSE_INPUT: {
      const temp = state.annotationTabMouseInput;
      temp.push(action.payload);
      return { ...state, annotationTabMouseInput: temp };
    }
    case Constants.SAVE_SCREENSHOT_DATA: {
      return { ...state, imageData: action.payload };
    }
    default:
      return state;
  }
}
