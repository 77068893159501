import { Constants } from "../constant/constants";

const initialState = {
  imagePlaneTabStatus: false,
};
export function ImagePlaneReducer(state = initialState, action) {
  switch (action.type) {
    case Constants.SET_IMAGEPLANE_TAB_STATUS: {
      console.log("ImagePlaneReducer");
      let flag;
      if (state.imagePlaneTabStatus) {
        flag = false;
      } else flag = true;
      return { ...state, imagePlaneTabStatus: flag };
    }
    default:
      return state;
  }
}
