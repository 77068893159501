import { Constants } from "../constant/constants";

const initialState = {
  setEditSearchTabStatus: false,
  setEditSearchData: null,
};
export function EditSearchReducer(state = initialState, action) {
  switch (action.type) {
    case Constants.SET_EDIT_SEARCH_TAB_STATUS: {
      console.log("searchreducer");
      return { ...state, setEditSearchTabStatus: action.payload };
    }
    case Constants.SET_EDIT_SEARCH_DATA: {
      return { ...state, setEditSearchData: action.payload };
    }
    default:
      return state;
  }
}
