import React, { useState } from "react";
import "./MenuBar.css";
import MenuBarItem from "./MenubarItem";

const MenuBar = () => {
  const [collapse, setCollapse] = useState(false);

  const menubarList = [
    "Annotation Tracker",
    "Annotation Setting",
    "Selection Sets",
    "Appearance Templates",
    "Export Data",
    "Clash Tests",
    "Properties",
    "Object Tree",
    "Storey View",
    "Saved Views",
  ];

  const handleClick = () => {
    collapse ? setCollapse(false) : setCollapse(true);
  };

  return (
    <div className="menubar">
      <div className="menubar-btn" onClick={() => handleClick()}>
        <div
          className={
            collapse ? "menubar-btn-vertical" : "menubar-btn-horizonal"
          }
        >
          <div className="menubar-btn-line"></div>
          <div className="menubar-btn-line"></div>
          <div className="menubar-btn-line"></div>
        </div>
      </div>
      <div
        className={
          collapse ? "menubar-dropdown-show" : "menubar-dropdown-hidden"
        }
      >
        {menubarList.map((element, i) => (
          <MenuBarItem listTitle={element} key={i} />
        ))}
      </div>
    </div>
  );
};

export default MenuBar;
