import { Constants } from "../constant/constants";

const initialState = {
  setSearchSaveTabStatus: false,
  searchSaveList: [],
  searchSaveListFlag: false,
  searchSetDataFlag: false,
};
export function SearchSetsReducer(state = initialState, action) {
  switch (action.type) {
    case Constants.SET_SEARCHSAVETAB_STATUS: {
      return { ...state, setSearchSaveTabStatus: action.payload };
    }
    case Constants.SAVE_SEARCH_LIST: {
      console.log("searchreducer", action.payload);
      let flag;
      if (state.searchSaveListFlag) {
        flag = false;
      } else flag = true;
      return {
        ...state,
        searchSaveList: action.payload,
        searchSaveListFlag: flag,
      };
    }
    case Constants.SET_SEARCHSET_DATA_FLAG: {
      console.log("HERERERERE");
      let flag;
      if (state.searchSetDataFlag) {
        flag = false;
      } else flag = true;

      console.log("????????????", flag);
      return {
        ...state,
        searchSetDataFlag: flag,
      };
    }
    default:
      return state;
  }
}
