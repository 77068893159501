import React, { useState, useEffect } from "react";
import { toast } from "react-toastify";
import { connect } from "react-redux";

import {
  AnnotationsPlugin,
  math,
} from "https://cdn.jsdelivr.net/npm/@xeokit/xeokit-sdk/dist/xeokit-sdk.es.min.js";

import Modal from "../../components/Modal/Modal";
import Tab from "../../components/Tab/Tab";
import Tab2 from "../../components/Tab/Tab2";

import "./ClashDetectionTab.css";

import {
  setClashDetectionTabStatusAction,
  detectCollisionAction,
  clashDetectionListAction,
  setIssueTrackStatusAction,
  saveIssueArrayAction,
  saveLogArrayAction,
  setSearchSaveTabStatusAction,
  setAnnotationOptionTabAction,
  setClashTestTabStatusAction,
} from "../../action/xeokitAction";

import { apiHeader, BaseURL, recordsLimit } from "../../config/apiUrl";
import { Get, Post } from "../../axios/axios";
import { Constants } from "../../constant/constants";

import PNG_BINOCULAR from "../../asset/binocular.png";
import PNG_SAVE from "../../asset/save.png";

const ClashDetectionTab = (props) => {
  const issueArray = [
    {
      issueType: "issue-type1",
      issueTypeName: "0AR",
      issueName: "Architecture-",
    },
    {
      issueType: "issue-type1",
      issueTypeName: "0CV",
      issueName: "Civil-",
    },
    {
      issueType: "issue-type1",
      issueTypeName: "0EL",
      issueName: "Electrical-",
    },
    {
      issueType: "issue-type1",
      issueTypeName: "0FR",
      issueName: "Fire-",
    },
    {
      issueType: "issue-type1",
      issueTypeName: "0LA",
      issueName: "Landscape-",
    },
    {
      issueType: "issue-type1",
      issueTypeName: "0ME",
      issueName: "Mechanical-",
    },
    {
      issueType: "issue-type1",
      issueTypeName: "0SE",
      issueName: "Security-",
    },
    {
      issueType: "issue-type1",
      issueTypeName: "0SI",
      issueName: "Signage-Client Raised Issue",
    },
    {
      issueType: "issue-type1",
      issueTypeName: "0ST",
      issueName: "Structure",
    },
    {
      issueType: "issue-type2",
      issueTypeName: "1AR",
      issueName: "Arch_Coord-(add description here)",
    },
    {
      issueType: "issue-type2",
      issueTypeName: "1CV",
      issueName: "Civil_Coord-(add description here)",
    },
    {
      issueType: "issue-type2",
      issueTypeName: "1EL",
      issueName: "Elec_Coord-(add description here)",
    },
    {
      issueType: "issue-type2",
      issueTypeName: "1FR",
      issueName: "Fire_Coord-(add description here)",
    },
    {
      issueType: "issue-type2",
      issueTypeName: "1HI",
      issueName: "Hydr_Coord-(add description here)",
    },
    {
      issueType: "issue-type2",
      issueTypeName: "1LA",
      issueName: "Lands_Coord-(add description here)",
    },
    {
      issueType: "issue-type2",
      issueTypeName: "1ME",
      issueName: "Mech_Coord-(add description here)",
    },
  ];
  const [open, setOpen] = useState(false);

  const [isClashIssueOpen, setIsClashIssueOpen] = useState(false);
  const [isStampListOpen, setIsStampListOpen] = useState(false);

  const [showList, setShowList] = useState([]);

  const [searchSet1, setSearchSet1] = useState({});
  const [searchSet2, setSearchSet2] = useState({});

  const [searchSet1Flag, setSearchSet1Flag] = useState();
  const [searchSet2Flag, setSearchSet2Flag] = useState();

  const [clashDetectionName, setClashDetectionName] = useState(null);
  const [clashIssueComment, setClashIssueComment] = useState(null);

  const [selectedRows, setSelectedRows] = useState([]);

  const [selectedIssue, setSelectedIssue] = useState({
    issueType: null,
    issueTypeName: null,
    issueName: null,
  });

  const [showClashDetectionList, setShowClashDetectionList] = useState([]);

  const [showClashDetectionArray1, setShowClashDetectionArray1] = useState([]);
  const [showClashDetectionArray2, setShowClashDetectionArray2] = useState([]);

  const [isLoading, setIsLoading] = useState(false);
  const [page, setPage] = useState(1);
  const [search, setSearch] = useState("");
  const [totalPages, setTotalPages] = useState();

  const getAllListing = async (pg = page) => {
    const url = BaseURL(
      `searches/projects/${props.projectId}?page=${pg}&limit=${recordsLimit}&search=${search}`
    );
    setIsLoading(true);
    const response = await Get(url, props.token);
    if (response !== undefined) {
      console.log("response", response);
      setShowList(response?.data);
      setTotalPages(response?.data?.totalResults);
    }
    setIsLoading(false);
  };

  useEffect(() => {
    getAllListing();
  }, []);

  useEffect(() => {
    getAllListing();
  }, [open]);

  const defaultIssue = {
    id: 595,
    stamp: "1AR",
    title: "Arch_Coord - add decription here",
    type: "Standard issue",
    status: "open",
    assignee: "ben stokoe",
    priority: "none",
    deadline: "none",
    color: "red",
    created: "13/Aug/2024",
    reporter: "Nathan Lenarduzzi",
    wachers: [
      "Adrian Wilkins",
      "Alexander Salna",
      "Calhum Craig",
      "Nathan Lenarduzzi",
    ],
    tags: "coordination issue",
    defaultSheet: "---",
    room: "UE4B-LIVERBLE 2901",
    level: "LEVEL 29",
    area: "GFA RESIDENTIAL FAST",
  };
  const [isAnnotationOptionTabOpen, setIsAnnotationOptionTabOpen] =
    useState(false);

  const closeAnnotationOptionTab = () => {
    setIsAnnotationOptionTabOpen(false);
  };

  const getScreenShot = async () => {
    const img = new Image();
    console.log("CLASH DETECTION");
    // const nextFrame = () => {
    // This method will get a snapshot image, composed of
    // an image of the viewer canvas, overlaid with an image
    // of the HTML container element belonging to each installed
    // Viewer plugin.

    const retVal = await props.viewer.getSnapshotWithPlugins({
      format: "png",
      width: img.width * 3, // Upscale snapshot resolution 2x
      height: img.height * 3,
    });
    // .then((imageData) => {
    //   setImageData(imageData);

    //   // img.src = imageData;

    //   // const canvasImg = new Image();
    //   // canvasImg.src = imageData;

    //   props.viewer.camera.orbitYaw(5);
    //   // setTimeout(nextFrame, 200);
    // });
    // };

    // nextFrame();
    // setIsImageEditorOpen(true);
    // console.log(retVal);
    return retVal;
  };

  const updateScreenShot = async () => {
    console.log(props.updateScreenIssueId);
    console.log(props.IssuesArray);
    const retVal = await getScreenShot();
    props.IssuesArray[props.updateScreenIssueId].markUp = retVal;
  };

  const onClickIssueTrack = async () => {
    const reVal = await getScreenShot();
    // console.log(reVal);
    props.setIssueTrackStatusAction(true);
    const temp = structuredClone(props.IssuesArray);
    defaultIssue.id = temp.length + 1;
    defaultIssue.markUp = reVal;
    temp.push(defaultIssue);
    props.saveIssueArrayAction(structuredClone(temp));

    const tempLogsArray = structuredClone(props.logsArray);
    tempLogsArray.push([{ markUp: reVal }]);
    props.saveLogArrayAction(structuredClone(tempLogsArray));
  };
  // const [tempElement, setTempElement] = useState();
  useEffect(() => {
    // setShowList(props.searchSaveList);
    setShowClashDetectionList(props.clashDetectionList);
    console.log("show", showList);
  }, [props.searchSaveListFlag]);

  useEffect(() => {
    setShowClashDetectionList(props.clashDetectionList);
    console.log("show", showList);
  }, [props.clashDetectionListFlag]);

  useEffect(() => {
    console.log("clashdetection", props.clashDetectionArray1);
    const temp1 = [];
    props.clashDetectionArray1.map((element, i) => {
      temp1.push({ id: i, ...element });
    });
    const temp2 = [];
    props.clashDetectionArray2.map((element, i) => {
      temp2.push({ id: i, ...element });
    });
    setShowClashDetectionArray1(temp1);
    setShowClashDetectionArray2(temp2);
  }, [props]);

  useEffect(() => {
    if (props.viewer === null) return;

    showClashDetectionArray1.map((element, i) => {
      const entity = props.viewer.scene.objects[element.id];
      if (entity !== undefined) entity.selected = false;
    });

    selectedRows.map((element, i) => {
      const entity = props.viewer.scene.objects[element];
      if (entity !== undefined) entity.selected = true;
    });
  }, [selectedRows]);

  useEffect(() => {
    let flag = false;
    showClashDetectionArray1.map((element, i) => {
      if (element.id === props.addToClashDetectionFromView) flag = true;
    });
    if (flag) handleCheckboxChange(props.addToClashDetectionFromView);
  }, [props.selectContextMenuBtnStatus]);

  useEffect(() => {
    console.log("????");
    setSelectedRows([props.addToClashDetectionNormalClick]);

    showClashDetectionArray1.map((element, i) => {
      if (element.id === props.addToClashDetectionNormalClick) {
        const id = showClashDetectionArray2[i].id;
        const entity = props.viewer.scene.objects[id];
        if (entity !== undefined) entity.selected = true;
      }
    });
  }, [props.addToClashDetectionNormalClick]);

  const handleClose = () => {
    setOpen(false);
    setIsClashIssueOpen(false);
  };

  const handleOpen = () => {
    setOpen(true);
  };

  const getSearchSet1 = (e) => {
    console.log(e.target.textContent);
    console.log(props.searchSaveList);
    showList.map((element, i) => {
      if (element.name === e.target.textContent) {
        console.log(element);
        setSearchSet1({
          searchSetName: element.name,
          name: element.property,
          value: element.value,
        });
        setSearchSet1Flag(element.name);
      }
    });
  };
  const getSearchSet2 = (e) => {
    showList.map((element, i) => {
      if (element.name === e.target.textContent) {
        setSearchSet2({
          searchSetName: element.name,
          name: element.property,
          value: element.value,
        });
        setSearchSet2Flag(element.name);
      }
    });
  };

  const onChangeInputValue = (e) => {
    setClashDetectionName(e.target.value);
  };

  const onChangeClashIssueCommentValue = (e) => {
    setClashIssueComment(e.target.value);
  };

  const detectCollision = async () => {
    console.log("??", searchSet1);
    if (
      clashDetectionName === null ||
      searchSet1 === undefined ||
      searchSet2 === undefined
    ) {
      alert("Please select the SearchSetA and the SearchSetB");
      return;
    }
    props.detectCollisionAction({
      clashDetectionName: clashDetectionName,
      searchSet1: searchSet1,
      searchSet2: searchSet2,
    });
    showClashDetectionList.push({
      clashDetectionName: clashDetectionName,
      searchSet1: searchSet1,
      searchSet2: searchSet2,
    });

    const url = BaseURL(`clashes`);
    const params = {
      name: clashDetectionName,
      compare1: searchSet1.name,
      compare2: searchSet2.name,
      projects: [`${props.projectName}`],
    };

    setIsLoading(true);

    const response = await Post(url, params, apiHeader(props.token));
    if (response !== undefined) {
      console.log("response", response);
      toast.success("Clash added successfully");
    }

    setIsLoading(false);

    props.clashDetectionListAction(showClashDetectionList);
    props.setClashTestTabStatusAction(true);
    setOpen(false);
  };

  const onClickListItem = (e) => {
    console.log(e.target.textContent);
    showClashDetectionList.map((element, i) => {
      if (element.clashDetectionName === e.target.textContent) {
        console.log("MMMM", element.clashDetectionName);
        setClashDetectionName(e.target.textContent);
        setSearchSet1(element.searchSet1);
        setSearchSet2(element.searchSet2);
        props.detectCollisionAction({
          clashDetectionName: e.target.textContent,
          searchSet1: element.searchSet1,
          searchSet2: element.searchSet2,
        });
      }
    });
  };

  const closeTab = () => {
    props.setClashDetectionTabStatusAction(false);
  };

  const viewEntity = (e, element) => {
    console.log("...", element);
    const dd = props.viewer.scene.objects[element.id];
    console.log(dd);
    const viewer = props.viewer;
    const scene = viewer.scene;
    viewer.cameraFlight.flyTo(
      {
        aabb: dd.aabb,
        duration: 0.5,
      },
      () => {
        setTimeout(function () {
          scene.setObjectsHighlighted(scene.highlightedObjectIds, false);
        }, 500);
      }
    );
  };

  const onClickIssueBtn = (e, element) => {
    // setTempElement(element);
    if (!selectedRows.includes(element.id)) {
      handleCheckboxChange(element.id);
    }
    setIsClashIssueOpen(true);
  };

  const onClickSelectBtn = () => {
    if (isStampListOpen) setIsStampListOpen(false);
    else setIsStampListOpen(true);
  };

  const onClickStampItem = (e, element) => {
    setIsStampListOpen(false);
    console.log(element.issueName);
    setSelectedIssue(element);
  };

  const onClickCreateIssueBtn = () => {
    props.setSearchSaveTabStatusAction(false);
    // setIsAnnotationOptionTabOpen(true);
    props.setAnnotationOptionTabAction(true);
    setIsClashIssueOpen(false);

    console.log("selectedRows", selectedRows);

    const annotations = new AnnotationsPlugin(props.viewer, {
      container: document.getElementById("annotationsContainer"),
      // Default HTML template for marker position
      markerHTML:
        "<div class='annotation-marker' style='background-color: {{markerBGColor}};'>{{glyph}}</div>",
      // Default HTML template for label
      // labelHTML:
      //   "<div class='annotation-label' style='background-color: {{labelBGColor}};'>" +
      //   "<div class='annotation-title'>{{title}}</div><div class='annotation-desc'>{{description}}</div></div>",
      // Default values to insert into the marker and label templates
      values: {
        markerBGColor: "white",
        labelBGColor: "red",
        glyph: "X",
        //   title: "Untitled",
        //   description: "No description",
      },
    });
    annotations.on("markerClicked", (annotation) => {
      annotation.labelShown = !annotation.labelShown;
    });

    let i = 1;

    selectedRows.map((element, i) => {
      console.log("i", i);
      const tempElement = props.viewer.scene.objects[element];
      const aabb = tempElement.aabb;
      const center = [
        (aabb[0] + aabb[3]) / 2,
        (aabb[1] + aabb[4]) / 2,
        (aabb[2] + aabb[5]) / 2,
      ];
      const canvasPos = worldToCanvas(props.viewer, center);
      // const canvasPos = [613.0423424, 536.830349324];
      console.log("canvasPos", canvasPos);
      const hit = props.viewer.scene.pick({
        canvasPos,
      });
      console.log("hit.", hit);
      const annotationStyle = selectedIssue.issueType;
      annotations.createAnnotation({
        id: 1,
        worldPos: center, // <<------- initializes worldPos and entity from PickResult
        // PickResult: hit,
        occludable: true, // Optional, default is true
        markerShown: true, // Optional, default is true
        labelShown: true, // Optional, default is true
        values: {
          // HTML template values
          glyph: selectedIssue.issueTypeName,
          fontColor:
            annotationStyle === "issue-type1" ? "rgb(240, 217, 9)" : "red",
          borderColor:
            annotationStyle === "issue-type1" ? "rgb(240, 217, 9)" : "red",
          //   title: "My annotation " + i,
          //   description: "My description " + i,
        },
      });
    });

    // const annotations = new AnnotationsPlugin(props.viewer, {
    //   // Default HTML template for marker position
    //   markerHTML: `<div class='clash-detection-annotation-marker' style='background-color: {{markerBGColor}}; color: {{fontColor}}; border-color: {{borderColor}}'>{{glyph}}</div>`,
    //   // Default HTML template for label
    //   // labelHTML:
    //   //   "<div class='annotation-label' style='background-color: {{labelBGColor}};'>" +
    //   //   "<div class='annotation-title'>{{title}}</div><div class='annotation-desc'>{{description}}</div></div>",
    //   // Default values to insert into the marker and label templates
    //   values: {
    //     markerBGColor: "white",
    //     labelBGColor: "red",
    //     glyph: "X",
    //     //   title: "Untitled",
    //     //   description: "No description",
    //   },
    // });
    // annotations.on("markerClicked", (annotation) => {
    //   annotation.labelShown = !annotation.labelShown;
    // });
    // selectedRows.map((element, i) => {
    //   console.log("i", i);
    //   const tempElement = props.viewer.scene.objects[element];
    //   const aabb = tempElement.aabb;
    //   const center = [
    //     (aabb[0] + aabb[3]) / 2,
    //     (aabb[1] + aabb[4]) / 2,
    //     (aabb[2] + aabb[5]) / 2,
    //   ];
    //   const canvasPos = worldToCanvas(props.viewer, center);
    //   // const canvasPos = [613.0423424, 536.830349324];
    //   console.log("canvasPos", canvasPos);
    //   const hit = props.viewer.scene.pick({
    //     canvasPos,
    //   });
    //   console.log("hit.", hit);
    //   const annotationStyle = selectedIssue.issueType;
    //   annotations.createAnnotation({
    //     id: 1,
    //     worldPos: center, // <<------- initializes worldPos and entity from PickResult
    //     // PickResult: hit,
    //     occludable: true, // Optional, default is true
    //     markerShown: true, // Optional, default is true
    //     labelShown: true, // Optional, default is true
    //     values: {
    //       // HTML template values
    //       glyph: selectedIssue.issueTypeName,
    //       fontColor:
    //         annotationStyle === "issue-type1" ? "rgb(240, 217, 9)" : "red",
    //       borderColor:
    //         annotationStyle === "issue-type1" ? "rgb(240, 217, 9)" : "red",
    //       //   title: "My annotation " + i,
    //       //   description: "My description " + i,
    //     },
    //   });
    // });

    // viewEntity("e", tempElement);
  };

  // Function to convert worldPos to canvasPos
  function worldToCanvas(viewer, worldPos) {
    const scene = viewer.scene;
    const camera = viewer.camera;

    // Create the combined view-projection matrix
    const viewMatrix = camera.viewMatrix;
    const projMatrix = camera.projMatrix;
    const viewProjMatrix = math.mulMat4(projMatrix, viewMatrix, []);

    // Convert the world position to homogeneous clip coordinates
    const clipPos = math.transformPoint4(viewProjMatrix, [...worldPos, 1.0]);

    // Perspective division to get normalized device coordinates (NDC)
    const ndcPos = [
      clipPos[0] / clipPos[3],
      clipPos[1] / clipPos[3],
      clipPos[2] / clipPos[3],
    ];

    // Convert NDC to canvas position
    const canvas = scene.canvas.canvas;
    const canvasPos = [
      (ndcPos[0] * 0.5 + 0.5) * canvas.width,
      (1.0 - (ndcPos[1] * 0.5 + 0.5)) * canvas.height,
    ];

    console.log("canvaPos ==== ", canvasPos);
    return canvasPos;
  }

  const handleCheckboxChange = (id) => {
    console.log("id", id);
    setSelectedRows((prevSelectedRows) =>
      prevSelectedRows.includes(id)
        ? prevSelectedRows.filter((rowId) => rowId !== id)
        : [...prevSelectedRows, id]
    );

    showClashDetectionArray1.map((element, i) => {
      if (element.id === id) {
        const id = showClashDetectionArray2[i].id;
        const entity = props.viewer.scene.objects[id];
        if (entity !== undefined) entity.selected = true;
      }
    });
  };
  return (
    <>
      <div className="clash-detection-tab">
        <Tab
          isOpen={props.setClashDetectionTabStatus}
          onClose={closeTab}
          tabName={"Clash Detection"}
        >
          <div className="clash-detection-tab-toolbar">
            <div>
              <img
                className="save-btn"
                src={PNG_SAVE}
                alt="Save"
                onClick={handleOpen}
              />
            </div>
            <Modal isOpen={open}>
              <div className="clash-detection-tab-modal">
                <div className="modal-header">
                  <label>Clash Test</label>
                  <span className="modal-close" onClick={handleClose}>
                    X
                  </span>
                </div>
                <div className="modal-body">
                  <div className="modal-body-list">
                    <div className="modal-body-list-colum">
                      <h5>SearchSet A</h5>
                      <ul className="modal-ul">
                        {showList?.map((_, i) => (
                          <li
                            onClick={(e) => getSearchSet1(e)}
                            className={
                              searchSet1Flag === _.name
                                ? "modal-li-clicked"
                                : "modal-li"
                            }
                            key={i}
                            value={_.name}
                          >
                            <div>
                              <img src={PNG_BINOCULAR} />
                            </div>
                            <div>{_.name}</div>
                          </li>
                        ))}
                      </ul>
                    </div>
                    <div className="modal-body-list-colum">
                      <h5>SearchSet B</h5>
                      <ul className="modal-ul">
                        {showList?.map((_, i) => (
                          <li
                            onClick={(e) => getSearchSet2(e)}
                            className={
                              searchSet2Flag === _.name
                                ? "modal-li-clicked"
                                : "modal-li"
                            }
                            key={i}
                            value={_.name}
                          >
                            <div>
                              <img src={PNG_BINOCULAR} />
                            </div>
                            <div>{_.name}</div>
                          </li>
                        ))}
                      </ul>
                    </div>
                  </div>
                </div>
                <div>
                  <input
                    onChange={(e) => onChangeInputValue(e)}
                    placeholder="Clash Test Name"
                  />
                </div>
                <div>
                  <button
                    className="custom-button"
                    onClick={() => detectCollision()}
                  >
                    Done
                  </button>
                </div>
              </div>
            </Modal>
            <Modal isOpen={isClashIssueOpen}>
              <div className="clash-detection-tab-modal">
                <div className="modal-header">
                  <label>Clash Test</label>
                  <span className="modal-close" onClick={handleClose}>
                    X
                  </span>
                </div>
                <div className="modal-body">
                  <div className="issue-option">
                    <div className="issue-option-item">
                      <label>Stamp: - </label>
                      <div className={selectedIssue.issueType}>
                        {selectedIssue.issueTypeName}
                      </div>
                      <div>{selectedIssue.issueName}</div>
                      <div className="issue-option-item-colum2">
                        <button
                          className="clashdetection-issue-button stamp-btn-width"
                          onClick={onClickSelectBtn}
                        >
                          select
                        </button>
                        <div
                          className={
                            isStampListOpen
                              ? "stamp-list visible-show"
                              : "visible-hide"
                          }
                        >
                          {issueArray.map((element, i) => {
                            return (
                              <div
                                className="stamp-list-item"
                                onClick={(e) => onClickStampItem(e, element)}
                                key={i}
                              >
                                <div className={element.issueType}>
                                  {element.issueTypeName}
                                </div>
                                <div>{element.issueName}</div>
                              </div>
                            );
                          })}
                        </div>
                      </div>
                    </div>
                    <div className="issue-option-item">
                      <label>Priority: - </label>
                      <div className="issue-option-item-colum2">
                        <button className="clashdetection-issue-button stamp-btn-width">
                          select
                        </button>
                      </div>
                    </div>
                    <div className="issue-option-item">
                      <label>Type: - </label>
                      <div className="issue-option-item-colum2">
                        <button className="clashdetection-issue-button stamp-btn-width">
                          select
                        </button>
                      </div>
                    </div>
                  </div>
                  <div>
                    <label>Comment</label>
                    <input
                      className="issue-comment-input"
                      onChange={(e) => onChangeClashIssueCommentValue(e)}
                      placeholder="Clash Issue Comment"
                    />
                  </div>
                  {/* <ul className="modal-ul">
                      {showList.map((_, i) => (
                        <li
                          onClick={(e) => getSearchSet1(e)}
                          className={
                            searchSet1Flag === _.searchSetName
                              ? "modal-li-clicked"
                              : "modal-li"
                          }
                          key={i}
                          value={_.searchSetName}
                        >
                          <div>
                            <img src={PNG_BINOCULAR} />
                          </div>
                          <div>{_.searchSetName}</div>
                        </li>
                      ))}
                    </ul> */}
                </div>
                <div>
                  <button
                    className="custom-button"
                    onClick={onClickCreateIssueBtn}
                  >
                    Create Issue
                  </button>
                </div>
              </div>
            </Modal>
          </div>
          <div style={{ overflowX: "scroll", width: "450px", height: "720px" }}>
            <table className="table">
              <tbody>
                <tr>
                  <th>
                    <input
                      type="checkbox"
                      onChange={(e) => {
                        if (e.target.checked) {
                          setSelectedRows(
                            showClashDetectionArray1.map((row) => row.id)
                          );
                        } else {
                          setSelectedRows([]);
                        }
                      }}
                      checked={
                        selectedRows.length === showClashDetectionArray1.length
                      }
                    />
                  </th>
                  <th>Model Entity1</th>
                  <th>Model Entity2</th>
                  <th>Set Annotation</th>
                </tr>
                {showClashDetectionArray1.map((element, i) => {
                  return (
                    <tr
                      key={i}
                      className={
                        selectedRows.includes(element.id) ? "selected" : ""
                      }
                    >
                      <td>
                        <input
                          type="checkbox"
                          onChange={() => handleCheckboxChange(element.id)}
                          checked={selectedRows.includes(element.id)}
                        />
                      </td>
                      <td onClick={(e) => viewEntity(e, element)}>
                        {element.id}
                      </td>
                      <td
                        onClick={(e) =>
                          viewEntity(e, showClashDetectionArray2[i])
                        }
                      >
                        {showClashDetectionArray2[i].id}
                      </td>
                      <td>
                        {" "}
                        <button
                          className="clashdetection-issue-button"
                          onClick={(e) => onClickIssueBtn(e, element)}
                        >
                          issue
                        </button>
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </div>
          {/* <ul className="clash-detection-tab-ul">
          {showClashDetectionList.map((_, i) => (
            <li
              className="clash-detection-tab-li-item"
              key={i}
              value={_.searchSetName}
              onClick={(e) => onClickListItem(e)}
            >
              <div></div>
              <div>{_.clashDetectionName}</div>
            </li>
          ))}
        </ul> */}
        </Tab>
      </div>
      <div className="annotation-option-tab">
        <Tab2
          isOpen={isAnnotationOptionTabOpen}
          onClose={closeAnnotationOptionTab}
          tabName={"AnnotationOption Tab"}
        >
          <button className="custom-button" onClick={() => updateScreenShot()}>
            Update scrrenshot
          </button>
          <button className="custom-button" onClick={() => onClickIssueTrack()}>
            Issue Track
          </button>
        </Tab2>
      </div>
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    setClashDetectionTabStatus:
      state.ClashDetectionReducer.setClashDetectionTabStatus,
    clashDetectionList: state.ClashDetectionReducer.clashDetectionList,
    clashDetectionListFlag: state.ClashDetectionReducer.clashDetectionListFlag,
    searchSaveList: state.SearchSetsReducer.searchSaveList,
    searchSaveListFlag: state.SearchSetsReducer.searchSaveListFlag,
    clashDetectionArray1: state.ClashDetectionReducer.clashDetectionArray1,
    clashDetectionArray2: state.ClashDetectionReducer.clashDetectionArray2,
    addToClashDetectionFromView:
      state.xeokitReducer.addToClashDetectionFromView,
    addToClashDetectionNormalClick:
      state.xeokitReducer.addToClashDetectionNormalClick,
    selectContextMenuBtnStatus: state.xeokitReducer.selectContextMenuBtnStatus,
    viewer: state.xeokitReducer.viewer,

    IssuesArray: state.IssueTrackReducer.IssuesArray,
    logsArray: state.IssueTrackReducer.logsArray,
    updateScreenIssueId: state.IssueTrackReducer.updateScreenIssueId,
    projectName: state.ProjectReducer.projectName,
    projectId: state.ProjectReducer.projectId,
    token: state.AuthReducer.token,
  };
};

const mapDispatchToProps = {
  setClashDetectionTabStatusAction,
  detectCollisionAction,
  clashDetectionListAction,
  setIssueTrackStatusAction,
  saveIssueArrayAction,
  saveLogArrayAction,
  setSearchSaveTabStatusAction,
  setAnnotationOptionTabAction,
  setClashTestTabStatusAction,
};

export default connect(mapStateToProps, mapDispatchToProps)(ClashDetectionTab);
