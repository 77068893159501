import React from "react";
import { connect } from "react-redux";
import { Navigate } from "react-router-dom";

import Header from "../container/Header/Header";
import Toolbar from "../container/ToolBar/Toolbar";
import MenuBar from "../container/MenuBar/MenuBar";
import PropertyTab from "../container/PropertyTab/PropertyTab";
import SearchTab from "../container/SearchTab/SearchTab";
import SearchSetsTab from "../container/SearchSetsTab/SearchSetsTab";
import EditSearchTab from "../container/EditSearchTab/EditSearchTab";
import ClashDetectionTab from "../container/ClashDetectionTab/ClashDetectionTab";
import ClashTestTab from "../container/ClashTestTab/ClashTestTab";
import AppearenceProfileTab from "../container/AppearenceProfileTab/AppearenceProfileTab";
import AppearenceTemplateTab from "../container/AppearenceTemplateTab/AppearenceTemplateTab";
import AnnotationTab from "../container/AnnotationTab/AnnotationTab";
import XeokitViewer from "../container/XeokitViewer/XeokitViewer";
import Measure from "../container/XeokitViewer/Measure";
import Thumbnail from "../container/Thumbnail/Thumbnail";
import Footer from "../container/Footer/Footer";
import IssueTrack from "../container/IssueTrack/IssueTrack";
import FileUpload from "../container/XeokitViewer/FileUpload";
import TreeViewTab from "../container/TreeViewTab/TreeViewTab";
import ImagePlaneTab from "../container/ImagePlane/ImagePlane";

import "../styles/mainPage.css";

const MainPage = (props) => {
  return props.projectName ? (
    <div className="mainpage checkupdate">
      <Header />
      <div className="model-viewer">
        {/* {props.setModelLoadFlage ? (
          <>
            <XeokitViewer />
            <Toolbar />
            <MenuBar />
            <PropertyTab />
            <SearchTab />
            <SearchSetsTab />
            <EditSearchTab />
            <ClashDetectionTab />
            <AppearenceProfileTab />
            <AppearenceTemplateTab />
            <AnnotationTab />
            <Measure />
            <Thumbnail />
            <IssueTrack />

            <TreeViewTab />
          </>
        ) : (
          <FileUpload />
        )} */}
        <XeokitViewer />
        <Toolbar />
        <MenuBar />
        <PropertyTab />
        <SearchTab />
        <SearchSetsTab />
        <EditSearchTab />
        <ClashDetectionTab />
        <ClashTestTab />
        <AppearenceProfileTab />
        <AppearenceTemplateTab />
        <AnnotationTab />
        <Measure />
        <Thumbnail />
        <IssueTrack />
        <TreeViewTab />
        <ImagePlaneTab />
      </div>
      <Footer />
    </div>
  ) : (
    <Navigate replace to="/projects" />
  );
};

// export default mainPage;
const mapStateToProps = (state) => {
  return {
    setModelLoadFlage: state.xeokitReducer.setModelLoadFlage,
    projectName: state.ProjectReducer.projectName,
  };
};

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(MainPage);
