import React, { useEffect, useState } from "react";

import DetailTab from "./DetailTab";
import LogTab from "./LogTab";

import "./TabPanel.css";

const TabPanel = ({
  imageData,
  detailTabData,
  saveUpdatedIssue,
  logTabData,
}) => {
  const [activeTab, setActiveTab] = useState(0);

  const tabs = [
    {
      label: "Details",
      content: (
        <DetailTab
          detailTabData={detailTabData}
          saveUpdatedIssue={saveUpdatedIssue}
        ></DetailTab>
      ),
    },
    {
      label: "Activity Logs",
      content: (
        <LogTab
          logTabData={logTabData}
          issueId={detailTabData.id}
          saveUpdatedIssue={saveUpdatedIssue}
        ></LogTab>
      ),
    },
  ];

  return (
    <div className="tab-panel">
      <div className="tab-panel-title">Issue {detailTabData.id}</div>
      <ul className="tab-panel-list">
        {tabs.map((tab, index) => (
          <li
            key={index}
            className={`tabs ${activeTab === index ? "active" : ""}`}
            onClick={() => setActiveTab(index)}
          >
            {tab.label}
          </li>
        ))}
      </ul>
      <div className="tab-panel-content">{tabs[activeTab].content}</div>
    </div>
  );
};

export default TabPanel;
