import { Constants } from "../constant/constants";

const initialState = {
  actionBuffer: [],
};
export function ActionBufferReducer(state = initialState, action) {
  switch (action.type) {
    case Constants.SAVE_ACTION_BUFFER: {
      return { ...state, actionBuffer: action.payload };
    }
    default:
      return state;
  }
}
