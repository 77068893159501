import React from "react";
import "./MenubarItem.css";
import ToggleSwitch from "../../components/ToggleSwitch/ToggleSwitch";
import CenterIcon from "../../asset/center.png";

const MenuBarItem = ({ listTitle }) => {
  return (
    <div className="item">
      <div>{listTitle}</div>
      <div className="item-btn">
        <div>
          <ToggleSwitch label={listTitle} />
        </div>
        <div className="item-btn-center">
          <img src={CenterIcon} className="centericon" alt="centericon" />
        </div>
      </div>
    </div>
  );
};

export default MenuBarItem;
