import React, { useState } from "react";
import "./SectionPlaneItem.css";
import ToggleSwitch from "../../components/ToggleSwitch/ToggleSwitch";

import IMG_LOCK from "../../asset/lock.png";
import IMG_UNLOCK from "../../asset/unlock.png";
import IMG_RESET from "../../asset/reset.png";

const SectionPlaneItem = ({
  listTitle,
  switchStatus,
  lockStatus,
  id,
  onChange,
  onReset,
  onSetLock,
}) => {
  const [isLocked, setIsLocked] = useState(lockStatus);
  const handleToggleChange = (isChecked) => {
    console.log(`Toggle ${id} is ${isChecked ? "On" : "Off"}`);

    if (onChange) onChange(id, isChecked);
  };

  const handleResetBtn = () => {
    if (onReset) onReset(id);
  };
  const handleLockBtn = () => {
    lockStatus ? setIsLocked(false) : setIsLocked(true);
    if (onSetLock) onSetLock(id, lockStatus ? false : true);
  };
  return (
    <div className="sectionplane-item">
      <div>{listTitle}</div>
      <div className="sectionplane-item-btn">
        <div>
          <ToggleSwitch
            label={listTitle}
            switchStatus={switchStatus}
            onChange={handleToggleChange}
          />
        </div>
        <div className="sectionplane-item-lock">
          <img
            src={lockStatus ? IMG_LOCK : IMG_UNLOCK}
            className="lock-btn"
            alt="lock"
            onClick={handleLockBtn}
          />
        </div>
        <div className="sectionplane-item-reset">
          <img src={IMG_RESET} alt="reset" onClick={handleResetBtn} />
        </div>
      </div>
    </div>
  );
};

export default SectionPlaneItem;
