// Modal.js

import React from "react";

import "./Modal.css";

const Modal = ({ isOpen, onClose, children }) => {
  if (!isOpen) return null;

  return (
    <div onClick={onClose} className="modal">
      <div className="main-modal">{children}</div>
    </div>
  );
};

export default Modal;
